import { Col, message, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import BoxSelectPayment from "../../../../components/boxpayment/BoxSelectPayment";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postHelp2pay = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
}) => {
  return post(`${renderApiDomain() + "deposit/help2pay"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    ip: ip.toString().replace("\n", ""),
  });
};

export const getHelp2pay = () => {
  return get(`${renderApiDomain() + "deposit/help2pay"}`);
};

const Help2pay = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    banks: [],
    amount: 0,
    backURI: "",
    bank: "",
    currency: "",
    customer: "",
    datetime: "",
    frontURI: "",
    key: "",
    language: "",
    note: "",
    reference: "",
    host: "",
    clientIP: selectIp,
    host: "",
  });
  useEffect(() => {
    const postApiPayment = async () => {
      setLoading(true);
      const { data } = await getHelp2pay();
      setPaymentData((prev) => ({
        ...prev,
        banks: data.data,
      }));
      setLoading(false);
    };
    postApiPayment();
  }, []);

  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
        payment_id: paymentId,
        bank: el,
        ip: selectIp,
      };

      // const { data } = await postHelp2pay(payload);
      const { data } = await post(`${renderApiDomain() + "deposit/help2pay"}`, {
        amount_thb: +props.amount,
        account_trade_id: props.account.id,
        payment_id: paymentId,
        bank: el,
        ip: selectIp.toString().replace("\n", ""),
        deposit_coupon_id: isCoupon,
      });
      if (data) {
        await Promise.all([
          setPaymentData((prev) => ({
            ...prev,
            host: data.data.host,
            merchant: data.data.merchant,
            amount: data.data.amount,
            backURI: data.data.backURI,
            bank: data.data.bank,
            currency: data.data.currency,
            customer: data.data.customer,
            datetime: data.data.datetime,
            frontURI: data.data.frontURI,
            key: data.data.key,
            language: data.data.language,
            note: data.data.note,
            reference: data.data.reference,
            clientIP: data.data.clientIP,
          })),
        ]).then(() => {
          refPerfect.current.click();
        });
        // refPerfect.current.click();
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        {paymentData?.banks?.map((e, i) => (
          <Col xs={24} sm={12} lg={8}>
            <BoxSelectPayment
              onSubmit={() => onFinish(Object.keys(e)[0])}
              title={Object.keys(e)[0]}
              subtitle={paymentData.banks[i][Object.keys(e)[0]]}
            />
            {/* <ItemSelect onClick={() => onFinish(Object.keys(e)[0])}>
              <div>{Object.keys(e)[0]}</div>
              <div>{paymentData.banks[i][Object.keys(e)[0]]}</div>
            </ItemSelect> */}
          </Col>
        ))}
      </Row>
      <form method="POST" action={paymentData.host}>
        <p>
          <input type="hidden" name="Merchant" value={paymentData.merchant} />
          <input type="hidden" name="Currency" value={paymentData.currency} />
          <input type="hidden" name="Customer" value={paymentData.customer} />
          <input type="hidden" name="Reference" value={paymentData.reference} />
          <input type="hidden" name="Key" value={paymentData.key} />
          <input type="hidden" name="Amount" value={paymentData.amount} />
          <input type="hidden" name="Note" value={paymentData.note} />
          <input type="hidden" name="Datetime" value={paymentData.datetime} />
          <input type="hidden" name="FrontURI" value={paymentData.frontURI} />
          <input type="hidden" name="BackURI" value={paymentData.backURI} />
          <input type="hidden" name="Language" value={paymentData.language} />
          <input type="hidden" name="Bank" value={paymentData.bank} />
          <input type="hidden" name="ClientIP" value={paymentData.clientIP} />
          <input
            ref={refPerfect}
            type="submit"
            value="help2pay account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default Help2pay;
