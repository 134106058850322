import React, { useEffect, useState } from "react";
import RcQueueAnim from "rc-queue-anim";
import DemoAccount from "./DemoAccount";

import { Helmet } from "react-helmet";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import ArchiveMt5 from "./ArchiveMt5";
import { StyledAccountMain } from "../../components";
import styled from "styled-components";
import Banner from "./banner";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  addAccount,
  updateLaverage,
  deleteAccount,
  archiveAccount,
  getArchive,
} from "../../actions";

import { useSelector } from "react-redux";
import ProfileDashboard from "../../components/profiledashboard/ProfileDashboard";
import Accounts from "./Accounts";
import AccountsWelcome from "./AccountsWelcome";
import OpenAccount from "./OpenAccount";
import ModalWelcomeBonus from "./Bonus/ModalWelcomeBonus";
import Tutorial from "../../components/tutorial";
import ModalSeminar from "../../components/seminar";
import ModalCampaign from "../../components/campaign/ModalCampaign";
import ModalCloseCampaign from "../../components/campaign/ModalCloseCampaign";
const POPUP_TIMEOUT = 3600 * 1000; //
const TITLE = "Wallet | IUX";
const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  color: ${(props) => props.color};
  white-space: pre;
  padding: 0.8rem 1rem;
  font-weight: ${(props) => props.fontWeight};
  position: relative;
  &:after {
    display: block;
    content: "";
    margin-top: 0.8rem;
    border-bottom: 4px solid #1cb894;
    transform: scaleX(${(props) => (props.borderBottom ? 1 : 0)});
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;
const Wallet = (props) => {
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const accountTypeListDemo = useSelector(
    (state) => state.account.accountTypeListDemo
  );
  const dataCampaign = useSelector((state) => state.user.bonus_campaign);
  const [isModalCampaign, setIsModalCampaign] = useState(false);
  const [modalBonus, setModalBonus] = useState(
    localStorage.getItem("showbonus30") ? false : true
  );
  const accountAll = useSelector((state) => state.user.accounts);

  const pathName = window.location.pathname;
  const [select] = useState("MT5");
  const [isShowAnnouncement, setIsShowAnnouncement] = useState(true);
  const [isOpenModalSeminar, setIsOpenModalSeminar] = useState(
    localStorage.getItem("showSeminar") ? false : true
  );
  const onCloseCampaign = () => {
    setIsModalCampaign(false);
  };
  const handleCloseSeminar = () => {
    setIsOpenModalSeminar(false);
  };
  const onCloseModalBonus = () => {
    setModalBonus(false);
  };
  useEffect(() => {
    localStorage.setItem("showSeminar", false);
    localStorage.setItem("showbonus30", false);
  }, []);
  useEffect(() => {
    // if (props?.user?.profileCountry?.name !== "Malaysia") {
    //   setIsOpenModalSeminar(false);
    // }
  }, [props?.user]);
  useEffect(() => {
    // Check if popup should be displayed
    const lastSeen = localStorage.getItem("popupLastSeen");
    const now = Date.now();
    if (
      dataCampaign &&
      new Date(dataCampaign?.start_date) <= new Date() &&
      new Date(dataCampaign?.end_date) > new Date()
    ) {
      if (!lastSeen || now - lastSeen > POPUP_TIMEOUT) {
        setIsModalCampaign(true); // Show modal if 1 hour has passed or it’s first access
        localStorage.setItem("popupLastSeen", now); // Set current time as last seen
      }
    }
  }, [dataCampaign]);
  return (
    <RcQueueAnim>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {/* {user?.bonus_config?.bonus_countries?.find(
        (e) => e === user?.profile?.country_id
      ) &&
        isShowAnnouncement && (
          <div className="position-relative">
            <CloseOutlined
              style={{
                position: "absolute",
                top: 8,
                right: 8,
                zIndex: 2,
                cursor: "pointer",
              }}
              onClick={() => setIsShowAnnouncement(false)}
            />
            <Banner />
          </div>
        )} */}
      {isShowAnnouncement && (
        <div className="position-relative">
          <CloseOutlined
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 2,
              cursor: "pointer",
              background: "rgba(255, 255, 255, 0.3)",
              borderRadius: "50%",
              padding: "2px",
            }}
            onClick={() => setIsShowAnnouncement(false)}
          />
          <Banner />
        </div>
      )}
      <ProfileDashboard />
      <div className=" mt-3 user" style={{ borderRadius: 10 }}>
        <StyledAccountMain style={{ borderRadius: 10 }}>
          {pathName !==
            `/${localStorage.getItem("locale")}/wallet/account/openaccount` && (
            <>
              <div
                className="d-flex p-4 justify-content-between"
                key={"dashboardWallet"}
              >
                <p className="text-header-account-wallet">
                  {locale.t("dashboardWallet.account")}
                </p>
              </div>
              <div className="sub-menu-page-user">
                <div className="container user">
                  <div className="d-flex overflow-auto scroll-menu-hide-user ">
                    {props?.user?.accounts?.filter((e) => e.type === "welcome")
                      .length > 0 && (
                      <Link
                        to={`/${localStorage.getItem(
                          "locale"
                        )}/wallet/account/welcome`}
                      >
                        <MenuItem
                          // onClick={() => setIndex(9999)}
                          color={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/welcome`
                              ? "#000"
                              : "#8D8D8D"
                          }
                          borderBottom={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/welcome`
                          }
                          fontWeight={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/welcome`
                              ? "unset"
                              : "unset"
                          }
                        >
                          <div className="d-flex align-items-center">
                            Welcome
                          </div>
                        </MenuItem>
                      </Link>
                    )}
                    {accountTypeList &&
                      accountTypeList
                        ?.filter((e) => e.type === "real")
                        .map((e, i) => (
                          <>
                            {(e.active === 1 ||
                              accountAll.find(
                                (el) => el.account_type === e.display
                              )) && (
                              <Link
                                to={`/${localStorage.getItem(
                                  "locale"
                                )}/wallet/account/${e.display}`}
                                key={i}
                              >
                                <MenuItem
                                  // onClick={() => setIndex(i)}
                                  color={
                                    pathName ===
                                    `/${localStorage.getItem(
                                      "locale"
                                    )}/wallet/account/${e.display}`
                                      ? "#000"
                                      : "#8D8D8D"
                                  }
                                  borderBottom={
                                    pathName ===
                                    `/${localStorage.getItem(
                                      "locale"
                                    )}/wallet/account/${e.display}`
                                  }
                                  fontWeight={
                                    pathName ===
                                    `/${localStorage.getItem(
                                      "locale"
                                    )}/wallet/account/${e.display}`
                                      ? "unset"
                                      : "unset"
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    {e.display}
                                    {/* {e.name === "Raw" && (
                            <div className="newitems ml-2">New</div>
                          )} */}
                                  </div>
                                </MenuItem>
                              </Link>
                            )}
                          </>
                        ))}
                    {accountTypeListDemo?.length > 0 && (
                      <Link
                        to={`/${localStorage.getItem(
                          "locale"
                        )}/wallet/account/demo`}
                      >
                        <MenuItem
                          // onClick={() => setIndex(9999)}
                          color={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/demo`
                              ? "#000"
                              : "#8D8D8D"
                          }
                          borderBottom={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/demo`
                          }
                          fontWeight={
                            pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/demo`
                              ? "unset"
                              : "unset"
                          }
                        >
                          <div className="d-flex align-items-center">Demo</div>
                        </MenuItem>
                      </Link>
                    )}
                    <Link
                      to={`/${localStorage.getItem(
                        "locale"
                      )}/wallet/account/archive`}
                    >
                      <MenuItem
                        // onClick={() => setIndex(9999)}
                        color={
                          pathName ===
                          `/${localStorage.getItem(
                            "locale"
                          )}/wallet/account/archive`
                            ? "#000"
                            : "#8D8D8D"
                        }
                        borderBottom={
                          pathName ===
                          `/${localStorage.getItem(
                            "locale"
                          )}/wallet/account/archive`
                        }
                        fontWeight={
                          pathName ===
                          `/${localStorage.getItem(
                            "locale"
                          )}/wallet/account/archive`
                            ? "unset"
                            : "unset"
                        }
                      >
                        <div className="d-flex align-items-center">Archive</div>
                      </MenuItem>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          <Switch>
            <Route
              path={`/:lang/wallet/account/openaccount`}
              render={() => {
                return <OpenAccount selectPlatform={select} />;
              }}
            />
            <Route
              path={`/:lang/wallet/account/demo`}
              render={() => {
                return <DemoAccount selectPlatform={select} />;
              }}
            />
            <Route
              path={`/:lang/wallet/account/archive`}
              render={() => {
                return <ArchiveMt5 selectPlatform={select} />;
              }}
            />
            <Route
              path={`/:lang/wallet/account/welcome`}
              render={() => {
                return <AccountsWelcome selectPlatform={select} />;
              }}
            />
            <Route
              path={`/:lang/wallet/account/:type`}
              render={() => {
                return <Accounts selectPlatform={select} />;
              }}
            />
            <Redirect
              to={
                props?.user?.accounts?.filter((e) => e.type === "welcome")
                  .length > 0
                  ? `/:lang/wallet/account/welcome`
                  : `/:lang/wallet/account/Standard`
              }
            />
          </Switch>

          {isModalCampaign ? (
            <ModalCampaign isOpen={isModalCampaign} onClose={onCloseCampaign} />
          ) : (
            <>
              {props?.user?.bonus_config?.bonus_countries?.find(
                (e) => e === props?.user?.profile?.country_id
              ) &&
              props.user.accountTradeBonusId.isRecived === null &&
              props?.user?.accounts?.filter((e) => e.type === "welcome")
                .length === 0 ? (
                <>
                  {/* {props?.user?.profileCountry?.name === "Malaysia" &&
                moment().diff(moment([2024, 1, 17]), "days") >= 0 && (
                  <ModalSeminar
                    isOpen={isOpenModalSeminar}
                    handleClose={handleCloseSeminar}
                  />
                )} */}

                  <ModalWelcomeBonus
                    isOpen={modalBonus}
                    onClose={onCloseModalBonus}
                  />

                  {!modalBonus && (
                    <Tutorial
                      user={props.user}
                      countryId={props?.user?.profile?.country_id}
                      status={props.user.userVerify.status}
                    />
                  )}
                </>
              ) : (
                <>
                  <Tutorial
                    user={props.user}
                    countryId={props?.user?.profile?.country_id}
                    status={props.user.userVerify.status}
                  />
                </>
              )}
            </>
          )}
        </StyledAccountMain>
      </div>
    </RcQueueAnim>
  );
};
const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  addAccount,
  getArchive,
  archiveAccount,
  updateLaverage,
  deleteAccount,
})(Wallet);
