import { Col, Row, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import BoxSelectPayment from "../../../../components/boxpayment/BoxSelectPayment";
import Nodata from "../../../../components/nodata/Nodata";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postChannelFormPayment = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
  payment_key,
}) => {
  return post(`${renderApiDomain() + `deposit/${payment_key}`}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    ip: ip.toString().replace("\n", ""),
  });
};

export const getChannelFormPayment = ({ payment_key }) => {
  return get(`${renderApiDomain() + `deposit/${payment_key}`}`);
};

const ChannelFormPayment = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
  payment_key,
  paymentId,
  amount,
  account,
  onBackStep,
}) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState({
    banks: [],
    amount: 0,
    backURI: "",
    bank: "",
    currency: "",
    customer: "",
    datetime: "",
    frontURI: "",
    key: "",
    language: "",
    note: "",
    reference: "",
    host: "",
    clientIP: selectIp,
    host: "",
  });
  useEffect(() => {
    const postApiPayment = async () => {
      setLoading(true);
      try {
        const { data } = await getChannelFormPayment({ payment_key });
        setPaymentData((prev) => ({
          ...prev,
          banks: data.data,
        }));
        setLoading(false);
      } catch (error) {
        message.error(error.response.data.error);
        onBackStep("error");
        setLoading(false);
      }
    };
    postApiPayment();
  }, []);

  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        amount_thb: +amount,
        account_trade_id: account.id,
        payment_id: paymentId,
        bank: el,
        ip: selectIp.toString().replace("\n", ""),
        deposit_coupon_id: isCoupon,
      };
      // isCoupon && payload?.payload = isCoupon;
      const { data } = await post(
        `${renderApiDomain() + `deposit/${payment_key}`}`,
        payload
      );
      if (data) {
        await Promise.all([setPaymentData(data.data)]).then(() => {
          refPerfect.current.click();
        });
        // refPerfect.current.click();
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }

      onBackStep("error");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        {paymentData?.banks?.length > 0 ? (
          paymentData?.banks?.map((e, i) => (
            <Col xs={24} sm={12} lg={8} key={i}>
              <BoxSelectPayment
                onSubmit={() => onFinish(Object.keys(e)[0])}
                title={Object.keys(e)[0]}
                subtitle={paymentData.banks[i][Object.keys(e)[0]]}
              />
            </Col>
          ))
        ) : (
          <Nodata />
        )}
      </Row>
      <form method="POST" action={paymentData.host}>
        <p>
          {Object.keys(paymentData).map((e, i) => (
            <input
              type="hidden"
              name={e}
              value={Object.values(paymentData)[i]}
            />
          ))}
          <input
            ref={refPerfect}
            type="submit"
            value="help2pay account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default ChannelFormPayment;
