import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Select, Button, Input, Modal, Checkbox, Alert, Form } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { get } from "../../helper/request.js";
import { Helmet } from "react-helmet";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { GlobalOutlined } from "@ant-design/icons";
import { onOpenPolicy } from "../../helper/policy.js";
import { initMetaPixel } from "../../components/pixel/pixel.js";
import { renderApiDomain } from "../../helper/renderApiDomain.js";
import ImportantZa from "../../components/important-note/important-za.js";
import useLocationStorage from "../../helper/useLocationStorage.js";
const TITLE = "Sign Up | IUX";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

export const RegisterPage = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    initMetaPixel("432657519519784");
  }, []);
  useEffect(() => {
    get(renderApiDomain() + "countries")
      .then((res) => {
        setCountries(
          res.data.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      })
      .catch((e) => {});
  }, []);
  const onSubmit = (value) => {
    const history = props.history;
    const errors = [];
    let invite = urlParams.get("invite");
    let code = urlParams.get("code");
    setLoading(true);

    setButtonLoading(true);
    try {
      axios
        .post(renderApiDomain() + "register", {
          email: value.email,
          password: value.password,
          countries_id: value.countries,
          reference_code: sessionStorage.getItem("code") || code || null,
          invite_code: invite || null,
        })
        .then((res) => {
          if (res.data.code === 0) {
            window?.fbq("track", "Subscribe");
            Modal.success({
              title: "Register has been created successfully.",
              content: locale.t("register_verify_email"),
              onOk() {
                localStorage.setItem("code", "");
                localStorage.removeItem("code", "");
                sessionStorage.setItem("code", "");
                sessionStorage.removeItem("code", "");
                urlParams.get("hidenav") !== "hide"
                  ? history.push(`/${localStorage.getItem("locale")}/login`)
                  : window.open(
                      `${window.location.origin}/${localStorage.getItem(
                        "locale"
                      )}/login`
                    );
              },
            });
            setButtonLoading(false);
          } else {
            Modal.error({
              title: locale.t("dashboardWallet.your_account"),
              content: res.response.data.error,
            });
            errors.push(res.response.data.error);
          }
          setLoading(false);
        })
        .catch((e) => {
          Modal.error({
            title: locale.t("dashboardWallet.your_account"),
            content: e.response.data.error,
          });

          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      Modal.error({
        title: locale.t("dashboardWallet.your_account"),
        content: (
          <Alert
            message={locale.t("dashboardWallet.error_10")}
            type="success"
          />
        ),
      });
      setLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[A-Za-z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );

      if (getFieldValue("password")) {
        if (value.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject(locale.t("register_password_validate"));
        }
      } else {
        return Promise.reject(locale.t("register_password_validate"));
      }
    },
  });

  const [location] = useLocationStorage();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {urlParams.get("hidenav") !== "hide" && (
        <div className="bg-black pt-4">
          <div className="container">
            <div className="text-center text-white">
              {locale.t("login_top_1")}&nbsp;
              <span className="font-bold-iux text-white">
                {locale.t("login_top_2")}&nbsp;
              </span>
              {locale.t("login_top_3")}
            </div>
            {/* if you have to check ImportantZa component please change condition location !== 'ZA'  */}
            {location === "ZA" && <ImportantZa />}
          </div>
        </div>
      )}
      <section
        className={`d-flex flex-column h-auto ${
          urlParams.get("hidenav") !== "hide" && "bg-black"
        }`}
        style={{ background: "transparent" }}
      >
        <div className="text-center rectangle-card rectangle-card-login ml-auto mr-auto mt-4 mb-4 position-relative">
          {/* <Times
            className=" position-absolute"
            onClick={() =>
              props.history.push(`/${localStorage.getItem("locale")}`)
            }
          >
            <CloseOutlined style={{ color: "#A0A0A0" }} />
          </Times>{" "} */}
          <div className="logo-register">
            <Link
              to={`/${localStorage.getItem("locale")}`}
              style={{ padding: 0, overflowY: "auto" }}
            >
              <img
                alt="IUX"
                className="registerlogo border-logo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                }
              />
            </Link>
          </div>
          <h5
            className="mt-4 text-center fs-24 fw-700 text-white"
            style={{
              padding: "10px 0px 0px",
            }}
          >
            {locale.t("register_title")}
          </h5>
          <div className="mt-4" />
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 24 }}
            layout={"vertical"}
            wrapperCol={{ span: 24 }}
            // initialValues={{ remember: true }}
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={locale.t("register_choose_region")}
              name="countries"
              rules={[
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
              ]}
            >
              <Select
                className="login"
                style={{ width: "100%" }}
                size="large"
                // onChange={this.onSelectLocation}
                placeholder={
                  <React.Fragment>
                    <GlobalOutlined /> &nbsp;
                    {locale.t("register_choose_region_placeholder")}
                  </React.Fragment>
                }
              >
                {countries
                  .filter((e) => e.short_name !== "jp")
                  .map((country, index) => {
                    return (
                      <Option key={index} value={country.id}>
                        <span
                          className={`fi fi-${country?.short_name?.toLowerCase()}`}
                        />
                        &nbsp;
                        {country.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label={locale.t("register_email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
              ]}
            >
              <Input
                style={{ height: "42px" }}
                className="input-register login"
                placeholder={locale.t("register_email")}
                type="email"
              />
            </Form.Item>
            <Form.Item
              label={locale.t("register_password")}
              name="password"
              style={{ background: "#242424" }}
              rules={[
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
                validatorPassword,
              ]}
            >
              <Input.Password
                style={{
                  height: "42px",
                }}
                className="input-register login"
                placeholder={locale.t("register_password")}
                type="password"
              />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(locale.t("register_error_choose_policy"))
                        ),
                },
              ]}
            >
              <Checkbox className="text-white">
                {locale.t("register_policy")}
                <span
                  className="cursor-pointer text-primary-new-iux"
                  onClick={() => {
                    if (urlParams.get("hidenav") !== "hide") {
                      onOpenPolicy("condition_iux_market");
                    }
                  }}
                >
                  {locale.t("register_policy_link")}
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldTouched("countries") ||
                    !form.isFieldTouched("email") ||
                    !form.isFieldTouched("password") ||
                    !form.isFieldTouched("agreement") ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                  className="btn-primary-new-iux w-100"
                  loading={loading}
                >
                  {locale.t("register_button_text")}
                </Button>
              )}
            </Form.Item>
          </Form>
          <div>
            {urlParams.get("hidenav") !== "hide" && (
              <Link to={`/${localStorage.getItem("locale")}/login`}>
                <p className="text-center mt-4 d-flex justify-content-between">
                  <span className="text-white">
                    {locale.t("already_account")}&nbsp;
                  </span>
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                    className="text-primary-new-iux fs-14"
                  >
                    {locale.t("login_title")}
                  </span>
                </p>
              </Link>
            )}
          </div>
        </div>
      </section>{" "}
      {urlParams.get("hidenav") !== "hide" && (
        <FooterAuthPage locale={locale} location={location} />
      )}
    </>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(RegisterPage);
