import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import ReactApexCharts from "react-apexcharts";
import { Col, Row, Select } from "antd";

const CHART_SIZE = { width: 92, height: 92 };
const locale = require("react-redux-i18n").I18n;

const { Option } = Select;

const chartOptionsCheckIn = {
  chart: { sparkline: { enabled: true } },
  colors: ["#1CB894"],

  fill: {
    type: "",
    gradient: {
      shade: "light",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#D9D9D9"],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 90, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  legend: { show: false },
  plotOptions: {
    radialBar: {
      hollow: { size: "64%" },
      track: { margin: 0 },
      dataLabels: {
        name: { show: false },
        value: {
          offsetY: 6,
          fontSize: 12,
          color: "#1E1E1E",
        },
      },
    },
  },
};

function SwapNew({ isAccountId = null }) {
  // const profileCountry = useSelector((state) => state.user.profileCountry);
  const user = useSelector((state) => state.user);
  const account = useSelector((state) => state.user.accounts);
  const isalamic = useSelector((state) => state.user.profileCountry.isalamic);
  const [accountSelect, setAccountSelect] = useState(
    isAccountId ? account.findIndex((acc) => acc.id === isAccountId) : 0
  );

  function renderSelectForm() {
    return account;
  }

  const isFree =
    isalamic ||
    renderSelectForm()[accountSelect]?.swap_charge === 0 ||
    renderSelectForm().length === 0;

  const domain = {
    "http://localhost:3000": "https://beta.iux.za.com",
    "https://portal.iux.com": "https://wwww.iux.com",
    "https://portal-beta.iux.com": "https://beta.iux.za.com",
    "https://portal-staging.iux.com": "https://staging.iux.za.com",
  };

  const checkDomain = () => {
    return domain[window.location.origin];
  };

  return (
    <div
      className={`${!isAccountId && "bg-swap-status"} p-2 py-4 mb-5 mw-swap `}
    >
      <div className={"d-flex justify-content-between w-100"}>
        <div className="px-3 pb-1 w-100">
          <Row align={"middle"}>
            <Col
              span={24}
              sm={{ span: 12 }}
              md={{ span: 14 }}
              lg={{ span: 16 }}
              style={{ fontWeight: 400, marginBottom: "8px" }}
            >
              {locale.t("modal_swap_title")} : &nbsp;
              <span
                style={{
                  background: isFree ? "#D8FCE3" : "#FDECEB",
                  padding: "4px 8px",
                  borderRadius: 24,
                  color: isFree ? "#0BA239" : "#EF4533",
                }}
                // className="d-flex align-items-center "
              >
                {isFree ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.6673 8.49967C14.6673 12.1815 11.6825 15.1663 8.00065 15.1663C4.31875 15.1663 1.33398 12.1815 1.33398 8.49967C1.33398 4.81777 4.31875 1.83301 8.00065 1.83301C11.6825 1.83301 14.6673 4.81777 14.6673 8.49967ZM11.0007 6.49966C11.2611 6.76001 11.2611 7.18214 11.0007 7.44247L7.80545 10.6377C7.54512 10.8981 7.12298 10.8981 6.86265 10.6377L5.33405 9.10914C5.0737 8.84881 5.0737 8.42667 5.33405 8.16634C5.5944 7.90601 6.01651 7.90601 6.27686 8.16634L7.33405 9.22354L10.0579 6.49966C10.3183 6.23931 10.7404 6.23931 11.0007 6.49966Z"
                      fill="#0BA239"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.00068 15.1663C11.6826 15.1663 14.6674 12.1815 14.6674 8.49967C14.6674 4.81777 11.6826 1.83301 8.00068 1.83301C4.31876 1.83301 1.33398 4.81777 1.33398 8.49967C1.33398 12.1815 4.31876 15.1663 8.00068 15.1663ZM6.61013 6.16634C6.34978 5.90599 5.92767 5.90599 5.66732 6.16634C5.40697 6.42669 5.40697 6.8488 5.66732 7.10915L7.05782 8.49967L5.66732 9.89021C5.40697 10.1505 5.40697 10.5727 5.66732 10.833C5.92767 11.0933 6.34978 11.0933 6.61013 10.833L8.00068 9.44247L9.39115 10.833C9.65155 11.0933 10.0736 11.0933 10.334 10.833C10.5944 10.5727 10.5944 10.1505 10.334 9.89021L8.94348 8.49967L10.334 7.10915C10.5944 6.8488 10.5944 6.42669 10.334 6.16634C10.0736 5.90599 9.65155 5.90599 9.39115 6.16634L8.00068 7.55687L6.61013 6.16634Z"
                      fill="#EF4533"
                    />
                  </svg>
                )}
                &nbsp;{isFree ? "Free" : "Non-Free"}
              </span>
            </Col>
            <Col
              span={24}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 8 }}
              style={{ textAlign: "end" }}
            >
              {isAccountId === null && renderSelectForm()?.length > 0 && (
                <Select
                  size="large"
                  className="input-selected w-sm-100"
                  value={accountSelect}
                  onChange={(e, value) => {
                    setAccountSelect(value.value);
                  }}
                  style={{ width: "100%" }}
                >
                  {renderSelectForm()?.map((account, index) => {
                    return (
                      <Option key={index} value={index}>
                        <div className="d-flex">
                          <div>
                            {` ${
                              account.type === "welcome"
                                ? "Welcome"
                                : account.account_group.display
                            } ${account.account_number}`}
                          </div>
                          &nbsp;
                          <div
                            style={{
                              color:
                                account.api_server === "demo"
                                  ? "#FE9F01"
                                  : "#1CB894",
                              fontWeight: 500,
                            }}
                          >
                            {account.api_server === "api"
                              ? "Live1"
                              : account.api_server === "live2"
                              ? "Live2"
                              : account.api_server === "live3"
                              ? "Live3"
                              : account.api_server === "demo"
                              ? "Demo"
                              : ""}
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            className="mt-4 mb-4"
            gutter={[6, 6]}
          >
            <Col span={24} sm={{ span: 4 }} className="d-flex justify-center">
              <ReactApexCharts
                type="radialBar"
                series={[isFree ? 100 : 0]}
                options={chartOptionsCheckIn}
                {...CHART_SIZE}
              />
            </Col>
            <Col span={24} sm={{ span: 20 }} className="fw-300 ">
              <div className="ml-2">
                {account[accountSelect]?.type === "demo" ||
                account[accountSelect]?.type === "welcome"
                  ? locale.t("swap_welcome_demo")
                  : isalamic
                  ? locale.t("swap_islamic")
                  : isFree
                  ? locale.t("swap_none_islamic_free")
                  : locale.t("swap_none_free")}
              </div>
            </Col>
          </Row>
          {account[accountSelect]?.type === "demo" ||
          account[accountSelect]?.type === "welcome" ? (
            ""
          ) : isalamic ? (
            <div
              className="mt-3 fs-12 text-lightgray mb-5"
              style={{ fontWeight: 300 }}
            >
              {locale.t("des_swap_islamic")}
            </div>
          ) : (
            <>
              {isFree &&
                account[accountSelect]?.api_server !== "demo" &&
                account[accountSelect]?.api_server !== "welcome" && (
                  <div
                    className="mt-3 fs-12 text-lightgray mb-5"
                    style={{ fontWeight: 300 }}
                  >
                    <div className="mt-3 fs-12 text-lightgray mb-5">
                      <div>Notes :</div>
                      <ul style={{ paddingInlineStart: "20px" }}>
                        {/* <li>{locale.t("modal_note_des_0")}</li> */}
                        <li>{locale.t("modal_note_des_1")}</li>
                        <li>{locale.t("modal_note_des_2")}</li>
                        <div className={"ml-2 mt-3"}>
                          <div>{locale.t("swaps_are_charged")}:</div>
                          <li
                            style={{ listStyleType: "disc" }}
                            className="ml-4"
                          >
                            {locale.t("swaps_major")}: USDJPY (
                            {locale.t("only_wed")})
                          </li>
                          <li
                            style={{ listStyleType: "disc" }}
                            className="ml-4"
                          >
                            {locale.t("swaps_minor")}: AUDCAD, CADCHF, CADJPY,
                            CHFJPY, GBPAUD, GBPCAD, NZDCHF, USDHKD
                          </li>
                          <li
                            style={{ listStyleType: "disc" }}
                            className="ml-4"
                          >
                            {locale.t("swaps_metals")}: (except XAUUSD)
                          </li>
                          <li
                            style={{ listStyleType: "disc" }}
                            className="ml-4"
                          >
                            {locale.t("swaps_index")}: DXY
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end px-3">
        <button
          id="swap_rate_button"
          className="btn-primary-new-iux fw-700"
          onClick={() => {
            let ct = localStorage.getItem("country");
            if (ct === "ZA" || user?.profile?.country_id === 27) {
              window.location.assign(
                `${checkDomain()}/${localStorage.getItem(
                  "locale"
                )}/markets#swap`
              );
            } else {
              window.location.assign(
                `${window.location.origin.replace(
                  "portal",
                  "www"
                )}/${localStorage.getItem("locale")}/markets#swap`
              );
            }
          }}
          // onClick={() =>

          //   window.location.assign(
          //     `/${localStorage.getItem("locale")}/swap/${
          //       profileCountry?.isalamic === 0 ? "non-islamic" : "islamic"
          //     }/Standard`
          //   )
          // }
        >
          {`Swap rates `}
        </button>
      </div>
    </div>
  );
}

export default SwapNew;
