import React, { useState, useEffect } from "react";
import { Skeleton, message } from "antd";

import BoxScanQrCode from "../../../../components/boxpayment/BoxScanQrCode";
import { postPayment } from "../../../../helper/request";
import { useHistory } from "react-router-dom";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";
import { useDispatch } from "react-redux";
const locale = require("react-redux-i18n").I18n;
const PaymentQrcode = ({
  payment_key,
  amount,
  account,
  rate,
  paymentSelected,
  onBackStep,
}) => {
  const [prompt, setPrompt] = useState(null);
  const history = useHistory();
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  useEffect(() => {
    if (amount > 0) {
      fetchOdpx();
    }
  }, [amount]);
  const fetchOdpx = async () => {
    try {
      const request = {
        amount: (amount / rate).toFixed(2),
        amount_thb: amount,
        account_trade_id: account.id,
        deposit_coupon_id: isCoupon,
      };
      const { data } = await postPayment(
        `${renderApiDomain()}deposit/${payment_key}`,
        request
      );
      var blob = data;
      var str = btoa(String.fromCharCode.apply(null, new Uint8Array(blob)));
      setPrompt(`data:image/octet-stream;base64,${str}`);
    } catch (error) {
      console.log(error);
      var isblob = error.response.data;

      var textDecoder = new TextDecoder("utf-8");
      var jsonString = textDecoder.decode(new Uint8Array(isblob));

      var responseObject = JSON?.parse(jsonString);

      // history.push(`/${localStorage.getItem("locale")}/wallet/fund`);
      if (error.responseObject.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(responseObject?.error);
      }

      onBackStep("error");
      // window.location.reload();

      console.log(error);
      console.log(responseObject);
    }
  };
  return (
    <>
      {prompt ? (
        <>
          <BoxScanQrCode
            isQrcode={prompt}
            account={account}
            rate={rate}
            paymentSelected={paymentSelected}
            amount={amount}
          />
        </>
      ) : (
        <Skeleton.Button
          active={true}
          size={300}
          shape={"square"}
          block={false}
        />
      )}
    </>
  );
};

export default PaymentQrcode;
