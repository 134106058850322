import { get } from "./request";
import { CheckLanguage } from "./countryid";
import { browserName } from "react-device-detect";
import { renderApiDomain } from "./renderApiDomain";

export const onOpenPolicy = async (keyname, country_id) => {
  try {
    let url = `${renderApiDomain()}policy?key_name=${keyname}&country_id=${CheckLanguage(
      localStorage.getItem("locale")
    )}`;
    if (country_id === 27)
      url = `${renderApiDomain()}policy?key_name=${keyname}&country_id=${country_id}`;
    const { data } = await get(url);
    if (data.data.file_path.startsWith("https")) {
      if (browserName === "Safari" || browserName === "Mobile Safari") {
        window.location.replace(decodeURIComponent(data.data.file_path));
      } else {
        window.open(decodeURIComponent(data.data.file_path));
      }
      //
    } else {
      if (browserName === "Safari" || browserName === "Mobile Safari") {
        window.location.replace(
          `${process.env.REACT_APP_POLICY}policy/?keyname=${keyname}&country_id=1`
        );
      } else {
        window.open(
          `${process.env.REACT_APP_POLICY}policy/?keyname=${keyname}&country_id=1`
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};
