import { Col, message, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import BoxScanQrCode from "../../../../components/boxpayment/BoxScanQrCode";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  text-align: center;
  min-height: 90px;
  &:hover {
    background: #169376;
    color: #fff;
    box-shadow: 0 0 12px #169376;
  }
`;

export const postEchelon = ({
  amount_thb,
  account_trade_id,
  payment_op,
  payment_method,
  deposit_coupon_id,
}) => {
  return post(`${renderApiDomain() + "deposit/echelon"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_method: payment_method,
    payment_op: payment_op,
    deposit_coupon_id: deposit_coupon_id,
  });
};
export const getEchelon = (amount_thb) => {
  return get(`${renderApiDomain() + "methods/echelon?amount="}${amount_thb}`);
};
const NewEchelon = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const dispatch = useDispatch();
  const [linkPay, setLinkPay] = useState(null);
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentDuitnow, setpaymentDuitnow] = useState(null);
  const [showDuitnow, setShowDuitnow] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  // const [paymentData, setPaymentData] = useState({
  //   banks: [],
  //   amount: 0,
  //   backURI: "",
  //   bank: "",
  //   currency: "",
  //   customer: "",
  //   datetime: "",
  //   frontURI: "",
  //   key: "",
  //   language: "",
  //   note: "",
  //   reference: "",
  //   host: "",
  //   clientIP: selectIp,
  //   host: "",
  // });
  useEffect(() => {
    fetchQRCode();
  }, [props.account.id]);
  const fetchQRCode = async () => {
    setLoading(true);
    try {
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
        payment_op: "MY_PUBLIC",
        payment_method: "DUITNOW",
        deposit_coupon_id: isCoupon,
      };
      const { data } = await postEchelon(payload);
      setpaymentDuitnow(data.data.paymentMethodResponse.qrCode);
      setLoading(false);
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }
      // message.error(error.response.data.error);
      console.log(error);
    }
  };
  const fectchBank = async () => {
    try {
      const { data } = await getEchelon(+props.amount);
      setLoading(true);
      setTimeout(() => {
        fetchQRCode();
      }, 3000);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = async (code, methodCode) => {
    setLoading(true);
    const payload = {
      account_trade_id: props.account.id,
      amount_thb: +props.amount,
      payment_op: code,
      payment_method: methodCode,
      deposit_coupon_id: isCoupon,
      // ip: selectIp,
    };
    const { data } = await postEchelon(payload);
    if (data) {
      if (methodCode === "ONLINE") {
        await Promise.all([setLinkPay(data?.data?.redirect_url)]).then(() => {
          refPerfect.current.click();
        });
        setLoading(false);
      }
      if (methodCode === "DUITNOW") {
        setpaymentDuitnow(data.data.paymentMethodResponse.qrCode);
        setShowDuitnow(true);
        setLoading(false);
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div>
        {paymentDuitnow && (
          <BoxScanQrCode
            isQrcode={paymentDuitnow}
            account={props.account}
            rate={props.rate}
            paymentSelected={props.paymentSelected}
            amount={props.amount}
          />
        )}
      </div>
      {/* {showDuitnow ? (
        <div>
          <img
            src={paymentDuitnow}
            alt="qr code"
            width="100%"
            style={{ maxWidth: "400px" }}
          />
        </div>
      ) : (
        <div>
          {paymentData?.map((e, i) => (
            <div className="mb-4">
              <div className="mb-4">{e?.paymentMethodCode} :</div>
              <div className="row">
                {e.paymentOperators?.map((item, index) => (
                  <div className="col-12 col-lg-4 px-1">
                    <ItemSelect
                      onClick={() => onFinish(item.code, e.paymentMethodCode)}
                    >
                      <div>{item.name}</div>
                    </ItemSelect>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )} */}

      {/* <a ref={refPerfect} href={linkPay} hidden>
        Echelon
      </a> */}
    </Spin>
  );
};

export default NewEchelon;
