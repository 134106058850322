import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  updateAlertOpenAccount,
  updateModalCampaign,
} from "../../../../actions";
const locale = require("react-redux-i18n").I18n;
const BoxBannerDefaultCampaign = styled.div`
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: 100px;

  background: ${(props) => props.bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.bgcolor};
  @media (max-width: 480px) {
    height: 100%;
  }
`;
const TextGradientsWebTrade = styled.div`
  background: linear-gradient(180deg, #fff 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

function BannerDeposit() {
  const user = useSelector((state) => state.user);
  const dataCampaign = useSelector((state) => state.user.bonus_campaign);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonRecive = () => {
    if (
      new Date(dataCampaign?.start_date) <= new Date() &&
      new Date(dataCampaign?.end_date) > new Date()
    ) {
      if (
        user.accounts.filter(
          (account) =>
            account.type === "real" && account.account_type === "StandardBonus"
        ).length > 0
      ) {
        history.push(
          `/${localStorage.getItem("locale")}/wallet/fund/deposit?coupon=${
            dataCampaign?.id
          }`
        );
      } else {
        dispatch(updateAlertOpenAccount(true));
      }
    } else {
      dispatch(updateModalCampaign(true));
    }
  };
  return (
    <div>
      {dataCampaign &&
        new Date(dataCampaign?.start_date) <= new Date() &&
        new Date(dataCampaign?.end_date) > new Date() && (
          <BoxBannerDefaultCampaign
            bg="url('/assets/images/announcement/bg-campaign.png')"
            bgcolor={"#FFF"}
          >
            <div
              className="p-4 h-100 d-flex justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center banner-100"
              style={{ gap: "14px" }}
            >
              <div className="text-center">
                <TextGradientsWebTrade className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start">
                  {locale.t("campaign_title")}
                </TextGradientsWebTrade>
                <div className="text-center text-sm-left text-white">
                  {/* Open a live trading account with IUX and get up to
                    $30 bonus free */}
                  {locale.t("campaign_detail")}
                </div>
              </div>{" "}
              <button
                onClick={() => {
                  handleButtonRecive();
                }}
                className="btn-primary-new-outline-campaign px-5 fw-700  mt-3 mt-md-0 white-space-break"
                style={{
                  borderRadius: "10px",
                  whiteSpace: "nowrap",
                  backgroundColor: "#24332E",
                  height: "auto",
                  padding: "8px",
                }}
              >
                {locale.t("bonus_button")}
              </button>
            </div>
          </BoxBannerDefaultCampaign>
        )}
    </div>
  );
}

export default BannerDeposit;
