export const AUTH_CHANGED = "auth_changed";
export const AUTH_SUCCESS = "auth_success";
export const LOGOUT = "logout";
export const INITIALIZE_CHANGED = "initialize_chaged";
export const INITIALIZE_LOADING = "initialize_loading";

export const UPDATE_USER_PROFILE = "update_user_profile";
export const UPDATE_LOADING = "update_loading";
export const UPDATE_LOADING_ARCHIVE = "update_loading_archive";
export const SET_PROFILE_LOADING = "set_profile_loading";
export const FETCH_USER_ACCOUNT = "fetch_user_account";
export const SET_PROFILE = "set_profile";
export const SET_ARCHIVE = "set_archive";
export const SET_ACCOUNT = "set_account";
export const UPDTAE_ACCOUNT = "update_account";
export const SET_USER_VERIFY = "set_user_verify";
export const SET_USER_VERIFY_BOOKBANK = "set_user_verify_bookbank";
export const UPDATE_LOCALE = "update_locate";
export const UPDATE_VERIFIER_USER = "update_verifier_user";
export const UPDATE_MODAL_CAMPIAGN = "update_modal_campaign";
export const UPDATE_MODAL_OPEN_ACCOUNT = "update_modal_open_account";
export const UPDATE_MODAL_OPEN_ACCOUNT_ALERT =
  "update_modal_open_account_alert";
export const UPDATE_VERIFIER_BOOKBANK = "update_verifier_bookbank";

export const UPDATE_GETBONUS = "update_getbonus";

export const ADD_ACCOUNT = "add_mt4_account";
export const DELETE_ACCOUNT = "delete_mt4_account";
export const UPDATE_ACCOUNT = "update_mt4_account";
export const UPDATE_2FA = "update_2fa";
export const UPDATE_IS_AUTH = "update_is_auth";

export const ARCHIVE_ACCOUNT = "archive_mt4_account";
export const RESTORE_ACCOUNT = "restore_mt4_account";

export const UPDATE_LOADING_BANK = "bank";
export const SET_BANK = "set_bank";
export const UPDATE_LOADING_COUNTRIES = "update_countries";
export const SET_COUNTRIES = "set_countries";
export const SET_PROFILE_COUNTRIES = "set_profile_countries";
export const SET_LEVERAGE = "set_leverage";
export const SET_IPADDRESS = "set_ipaddress";
export const SET_BONUS_CONFIG = "set_bonus_config";
export const SET_BONUS_CAMPAIGN = "set_bonus_campaign";

export const SET_ACCOUNT_TYPE_LIST = "set_account_type_list";
export const SET_MODAL_BONUS = "set_modal_bonus";
export const SET_ACCOUNT_TYPE_LIST_DEMO = "set_account_type_list_demo";
export const LOADING_ACCOUNT_TYPE_LIST = "loading_account_type_list";
