import { Button, message, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAlertOpenAccount,
  updateModalOpenAccount,
} from "../../../actions";
const locale = require("react-redux-i18n").I18n;
function AlertOpenAccount() {
  const accounts = useSelector((state) => state.user.accounts);
  console.log(accounts);
  const isModalOpenAccount = useSelector(
    (state) => state.account.modalAlertOpenAccount
  );

  const onCloseModalAlert = () => {
    dispatch(updateAlertOpenAccount(false));
  };
  const handleCreateAccount = () => {
    if (accounts?.filter((e) => e.type === "real").length >= 6) {
      message?.error("The account opening has been limited only 6 acount.");
      onCloseModalAlert();
    } else {
      dispatch(updateModalOpenAccount("Standard+", true, true));
      onCloseModalAlert();
    }
  };
  const dispatch = useDispatch();
  return (
    <Modal
      className="w-modal width-modal-alert-openAccount  user user-change"
      closable={false}
      visible={isModalOpenAccount}
      title={false}
      onCancel={() => {
        onCloseModalAlert();
      }}
      footer={null}
    >
      <div className="">
        {" "}
        <div className="w-100  d-flex justify-content-center ">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12V22C18 23.1046 18.8954 24 20 24C21.1046 24 22 23.1046 22 22V12ZM20 26C18.8954 26 18 26.8954 18 28C18 29.1046 18.8954 30 20 30H20.02C21.1246 30 22.02 29.1046 22.02 28C22.02 26.8954 21.1246 26 20.02 26H20Z"
              fill="#FE9F01"
            />
          </svg>
        </div>
        <div className="text-center fw-700 fs-20 mt-3">
          {locale.t("open_account_alert_title")}
        </div>
        <div className="text-center fs-16 mt-2 text-neutral-grey ">
          {locale.t("open_account_alert_des")}
        </div>
        <div className="d-flex mt-4">
          <Button
            className="btn-dark-new-outline  w-100"
            onClick={() => onCloseModalAlert()}
          >
            {locale.t("not_now")}
          </Button>{" "}
          <Button
            className="btn-primary-new-iux w-100 ml-2 "
            onClick={() => {
              handleCreateAccount();
            }}
          >
            {locale.t("create")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AlertOpenAccount;
