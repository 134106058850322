import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Form,
  Input,
  Select,
  Alert,
  message,
  Divider,
} from "antd";
import { post } from "../../../helper/request";
import { onOpenPolicy } from "../../../helper/policy";
import { useSelector } from "react-redux";
import { renderApiDomain } from "../../../helper/renderApiDomain";
import {SERVER_NAME} from "../../../constants/server";
const locale = require("react-redux-i18n").I18n;

function CreateBonus(props) {
  const { isOpen, onCloseBonus } = props;
  const { Option } = Select;
  const [form] = Form.useForm();
  const leverages = useSelector((state) => state.user.leverages);
  const refAdd = React.createRef();
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [bonusVisibleModal, setBonusVisibleModal] = useState(false);
  const [accountNumber, setAccountNumber] = useState("1150");
  const [serverName, setServerName] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [buttonbonus, setButtonbonus] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const onChange = (e) => {
    setButtonbonus(e.target.checked);
  };
  const onCreate = async () => {
    try {
      const values = await form.validateFields();

      const data = {
        password: values.password,
        leverage: values.leverage,
      };

      setButtonLoading(true);
      post(renderApiDomain() + "bonus/profile", data)
        .then((res) => {
          let { data } = res;
          setAccountNumber(data?.data?.account_number);
          setServerName(data?.data?.api_server)
          setShowSuccess(true);

          setButtonLoading(false);
          // window.location.href =
          //   window.origin +
          //   `/${localStorage.getItem("locale")}/wallet/account/welcome`;

          //   Modal.info({
          //     title: locale.t("dashboardWallet.your_mt5"),
          //     content: (
          //       <Alert message={data?.data?.account_number} type="success" />
          //     ),
          //     onOk() {
          //       window.location.href =
          //         window.origin +
          //         `/${localStorage.getItem("locale")}/wallet/account/welcome`;
          //     },
          //   });
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      setButtonLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      // const regex = new RegExp(
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      // );
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,}$/
      );
      if (value?.match(regex)) {
        return Promise.resolve();
      } else {
        if (value) {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        } else {
          return Promise.reject(locale.t("dashboardWallet.input_error"));
        }
      }
    },
  });
  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={true}
      visible={isOpen}
      title={false}
      // title={showSuccess ? false : locale.t("dashboardWallet.modal.title")}
      onCancel={() => {
        form.resetFields();
        if (showSuccess) {
          window.location.href =
            window.origin +
            `/${localStorage.getItem("locale")}/wallet/account/welcome`;
          onCloseBonus(false);
        } else {
          onCloseBonus(false);
        }
      }}
      footer={[
        <div className="d-flex justify-content-between text-center"></div>,
      ]}
    >
      {" "}
      <Form
        form={form}
        ref={refAdd}
        initialValues={{
          currency: "USD",
          leverage: 3000,
        }}
        // layout={"vertical"}
        // className="w-modal width-modal-openAccountBonus user user-change"
      >
        {!showSuccess ? (
          <div className="p-4">
            <div className="d-flex justify-content-center w-100 fs-20 fw-700 pb-4">
              {locale.t("dashboardWallet.modal.title")}
            </div>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="mt_type"
                  rules={[
                    {
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <div>{locale.t("dashboardWallet.server")}</div>
                  <Input
                    disabled
                    defaultValue={"Metatrader 5"}
                    style={{ height: 40 }}
                    // style={styleInput}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <div>{locale.t("dashboardWallet.modal.accountMoney")}</div>
                  <Input disabled style={{ height: 40 }} defaultValue={"USD"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <div>{locale.t("dashboardWallet.accountType")}</div>
                  <Input
                    disabled
                    style={{ height: 40 }}
                    defaultValue={"Welcome bonus"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div>{locale.t("dashboardWallet.accountLeverage")}</div>
                <Form.Item
                  name="leverage"
                  rules={[
                    {
                      required: true,
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <Select size="large" placeholder="Leverage" listHeight={290}>
                    {leverages?.Standard &&
                      leverages?.Standard.map((e, i) => (
                        <Option value={e.values} key={i}>
                          <div>
                            <div className="row">
                              <div className="col-8 pr-0">
                                <div className="">1:{e.values} &nbsp;</div>
                                <Divider className="m-0" />
                              </div>
                              {(e.values === 1 ||
                                e.values === 100 ||
                                e.values === 1000) && (
                                <div
                                  className={`${
                                    e.values >= 1000
                                      ? "text-danger"
                                      : e.values < 100
                                      ? "text-primary-new-iux"
                                      : "text-yellow-new-iux"
                                  } col-4 text-right pl-0`}
                                >
                                  <div className=""> {e.title}</div>
                                  <Divider
                                    className={`${
                                      e.values >= 1000
                                        ? "bg-danger"
                                        : e.values < 100
                                        ? "bg-primary-iux"
                                        : "bg-yellow-iux"
                                    } m-0`}
                                    style={{
                                      background:
                                        e.values >= 1000
                                          ? "#B44844"
                                          : e.values < 100
                                          ? "#1cb894"
                                          : "rgb(241, 158, 3)",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <div>{locale.t("dashboardWallet.modal.accountPassword")}</div>
                <Form.Item
                  name="password"
                  rules={[
                    // {
                    //   required: true,
                    //   message: locale.t("dashboardWallet.input_error"),
                    // },
                    validatorPassword,
                  ]}
                >
                  <Input.Password
                    // style={{ height: 42 }}
                    type="password"
                    placeholder={locale.t(
                      "dashboardWallet.modal.accountPassword"
                    )}
                    className="user-password"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Button
              className="btn-primary-new-iux w-100 mt-4"
              key="submit"
              type="primary"
              loading={buttonLoading}
              disabled={buttonLoading}
              onClick={onCreate}
              // disabled={buttonbonus === false}
            >
              {locale.t("bonus_button")}
            </Button>
          </div>
        ) : (
          <div className="p-4">
            <div className="d-flex justify-content-center w-100 fs-20 fw-700">
              {locale.t("congrat")}
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column w-100 fs-12 mt-4">
              <div className="fs-14">{locale.t("congrat_detail")}</div>
              <img
                src="/assets/images/icons/dashboard/congratulations.png"
                alt="bonus30"
                className="mt-3"
              />
              <div className="fs-14 text-lightgray mt-3">
                {" "}
                {locale.t("account_number")}
              </div>
              <div className="fw-700 fs-14">{accountNumber}</div>
              <div className="fs-12 text-lightgray">{SERVER_NAME[serverName]}</div>
              <div className="text-center mt-3">
                {locale.t("bonus_create_success")}
              </div>
            </div>
          </div>
        )}{" "}
      </Form>
    </Modal>
  );
}

export default CreateBonus;
