import { Col, Row, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import Nodata from "../../../../components/nodata/Nodata";
import BoxSelectPayment from "../../../../components/boxpayment/BoxSelectPayment";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  text-align: center;
  &:hover {
    background: #169376;
    color: #fff;
    box-shadow: 0 0 12px #169376;
  }
`;
export const postChannelLinkPayment = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
  payment_key,
  deposit_coupon_id,
}) => {
  return post(`${renderApiDomain() + `deposit/${payment_key}`}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    deposit_coupon_id: deposit_coupon_id,
    // ip: ip.toString().replace("\n", ""),
  });
};

export const getChannelLinkPayment = ({ payment_key }) => {
  return get(`${renderApiDomain() + `methods/${payment_key}`}`);
};

const ChannelLinkPayment = ({
  payment_key,
  amount,
  account,
  rate,
  paymentSelected,
  paymentId,
  onBackStep,
}) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  const [linkPay, setLinkPay] = useState(null);
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState({
    banks: [],
    amount: 0,
    backURI: "",
    bank: "",
    currency: "",
    customer: "",
    datetime: "",
    frontURI: "",
    key: "",
    language: "",
    note: "",
    reference: "",
    host: "",
    clientIP: selectIp,
    host: "",
  });
  useEffect(() => {
    fectchBank();
  }, []);
  const fectchBank = async () => {
    try {
      const { data } = await getChannelLinkPayment({
        payment_key: payment_key,
      });
      setLoading(true);

      setPaymentData((prev) => ({
        ...prev,
        banks: data.data,
      }));
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.error);
      onBackStep("error");
      setLoading(false);
      console.log(error);
    }
  };
  const onFinish = async (el) => {
    setLoading(true);

    try {
      const payload = {
        account_trade_id: account.id,
        amount_thb: +amount,
        payment_id: paymentId,
        bank: el,
        payment_key: payment_key,
        deposit_coupon_id: isCoupon,
        // ip: selectIp,
      };
      const { data } = await postChannelLinkPayment(payload);
      if (data) {
        await Promise.all([setLinkPay(data?.data?.redirect_url)]).then(() => {
          refPerfect.current.click();
        });
        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }
      onBackStep("error");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        {paymentData?.banks?.length > 0 ? (
          paymentData?.banks?.map((e, i) => (
            <Col xs={24} sm={12} lg={8} key={i}>
              <BoxSelectPayment
                onSubmit={() => onFinish(e.bank)}
                title={e?.name}
                images={e?.logo}
                // subtitle={paymentData.banks[i][Object.keys(e)[0]]}
              />
            </Col>
          ))
        ) : (
          <Nodata />
        )}
      </Row>

      <a ref={refPerfect} href={linkPay} hidden>
        ChannelLinkPayment
      </a>
    </Spin>
  );
};

export default ChannelLinkPayment;
