import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
const locale = require("react-redux-i18n").I18n;
const CountdownTimer = ({ targetDate, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const [status, setStatus] = useState("running");

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      setStatus("completed");
      onComplete?.(); // Call the completion callback if provided
      return {};
    }

    return {
      days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      ),
      hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
        2,
        "0"
      ),
      minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
        2,
        "0"
      ),
      seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      // Clear interval when countdown is complete
      if (Object.keys(newTimeLeft).length === 0) {
        clearInterval(timer);
      }
    }, 1000);

    // Initial calculation
    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div
      className="text-center  "
      style={{ padding: "0 20px", margin: "12px 0" }}
    >
      {/* <Row justify="center" gutter={16} align={"middle"}>
        <Col span={5}>
          <div className="fs-12">Days</div>
        </Col>
        <span className="fs-32">&nbsp;</span>
        <Col span={5}>
          <div className="fs-12">Hours</div>
        </Col>
        <span className="fs-32">&nbsp;</span>
        <Col span={5}>
          <div className="fs-12">Minutes</div>
        </Col>
        <span className="fs-32">&nbsp;</span>
        <Col span={5}>
          <div className="fs-12">Seconds</div>
        </Col>
      </Row> */}
      <Row justify="center" gutter={0} align={"middle"}>
        <Col span={5}>
          <div className="fs-12" style={{ color: "#A3A3A3" }}>
            {locale.t("bonus_campaign_card_countdown.day")}
            {/* Days bonus_campaign_card_countdown */}
          </div>
          <div className="fs-32 fw-700">{timeLeft.days || "00"}</div>
        </Col>
        <Col span={1}>
          <div className="mt-3 ">
            <svg
              width="7"
              height="20"
              viewBox="0 0 7 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.519 3.756C0.519 2.90267 0.807 2.188 1.383 1.612C1.959 1.036 2.663 0.747999 3.495 0.747999C4.34833 0.747999 5.063 1.036 5.639 1.612C6.215 2.188 6.503 2.90267 6.503 3.756C6.503 4.588 6.215 5.292 5.639 5.868C5.063 6.444 4.34833 6.732 3.495 6.732C2.663 6.732 1.959 6.444 1.383 5.868C0.807 5.292 0.519 4.588 0.519 3.756ZM0.519 16.524C0.519 15.6707 0.807 14.956 1.383 14.38C1.959 13.804 2.663 13.516 3.495 13.516C4.34833 13.516 5.063 13.804 5.639 14.38C6.215 14.956 6.503 15.6707 6.503 16.524C6.503 17.356 6.215 18.06 5.639 18.636C5.063 19.212 4.34833 19.5 3.495 19.5C2.663 19.5 1.959 19.212 1.383 18.636C0.807 18.06 0.519 17.356 0.519 16.524Z"
                fill="#2F2F2F"
              />
            </svg>
          </div>
        </Col>

        <Col span={5}>
          <div className="fs-12" style={{ color: "#A3A3A3" }}>
            {locale.t("bonus_campaign_card_countdown.hour")}
          </div>
          <div className="fs-32 fw-700 ">{timeLeft.hours || "00"}</div>
        </Col>
        <Col span={1} className="relative">
          <div className="mt-3 ">
            <svg
              width="7"
              height="20"
              viewBox="0 0 7 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.519 3.756C0.519 2.90267 0.807 2.188 1.383 1.612C1.959 1.036 2.663 0.747999 3.495 0.747999C4.34833 0.747999 5.063 1.036 5.639 1.612C6.215 2.188 6.503 2.90267 6.503 3.756C6.503 4.588 6.215 5.292 5.639 5.868C5.063 6.444 4.34833 6.732 3.495 6.732C2.663 6.732 1.959 6.444 1.383 5.868C0.807 5.292 0.519 4.588 0.519 3.756ZM0.519 16.524C0.519 15.6707 0.807 14.956 1.383 14.38C1.959 13.804 2.663 13.516 3.495 13.516C4.34833 13.516 5.063 13.804 5.639 14.38C6.215 14.956 6.503 15.6707 6.503 16.524C6.503 17.356 6.215 18.06 5.639 18.636C5.063 19.212 4.34833 19.5 3.495 19.5C2.663 19.5 1.959 19.212 1.383 18.636C0.807 18.06 0.519 17.356 0.519 16.524Z"
                fill="#2F2F2F"
              />
            </svg>
          </div>
        </Col>
        <Col span={5}>
          <div className="fs-12" style={{ color: "#A3A3A3" }}>
            {locale.t("bonus_campaign_card_countdown.minute")}
          </div>
          <div className="fs-32 fw-700">{timeLeft.minutes || "00"}</div>
        </Col>
        <Col span={1}>
          <div className="mt-3 ">
            <svg
              width="7"
              height="20"
              viewBox="0 0 7 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.519 3.756C0.519 2.90267 0.807 2.188 1.383 1.612C1.959 1.036 2.663 0.747999 3.495 0.747999C4.34833 0.747999 5.063 1.036 5.639 1.612C6.215 2.188 6.503 2.90267 6.503 3.756C6.503 4.588 6.215 5.292 5.639 5.868C5.063 6.444 4.34833 6.732 3.495 6.732C2.663 6.732 1.959 6.444 1.383 5.868C0.807 5.292 0.519 4.588 0.519 3.756ZM0.519 16.524C0.519 15.6707 0.807 14.956 1.383 14.38C1.959 13.804 2.663 13.516 3.495 13.516C4.34833 13.516 5.063 13.804 5.639 14.38C6.215 14.956 6.503 15.6707 6.503 16.524C6.503 17.356 6.215 18.06 5.639 18.636C5.063 19.212 4.34833 19.5 3.495 19.5C2.663 19.5 1.959 19.212 1.383 18.636C0.807 18.06 0.519 17.356 0.519 16.524Z"
                fill="#2F2F2F"
              />
            </svg>
          </div>
        </Col>
        <Col span={5}>
          <div className="fs-12" style={{ color: "#A3A3A3" }}>
            {locale.t("bonus_campaign_card_countdown.second")}
          </div>
          <div className="fs-32 fw-700">{timeLeft.seconds || "00"}</div>
        </Col>
      </Row>
    </div>
  );
};

export default CountdownTimer;
