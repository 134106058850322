import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledAccountMain, SubStyledAccountWallet } from "../../components";
import { Button, Spin, message, Switch } from "antd";
import { useSelector } from "react-redux";
import { MailFilled, MobileOutlined, CheckOutlined } from "@ant-design/icons";
import Dialog from "./dialog";
import { get, post } from "../../helper/request";
import { getUser, getUserv2, getUserv3, update2FA } from "../../actions/user";
import { useDispatch } from "react-redux";
import VerifyBookBank from "../dashboard-content/fund/verifies/VerifyBookBank";
import { CheckPhoneCode } from "../../components/check-phonecode";
import ChangePassword from "./ChangePassword";
import { ClipLoader } from "react-spinners";
import { renderApiDomain } from "../../helper/renderApiDomain";
import { getUserCustomToken, putUserOtpEnable } from "../../api/verifyuser";
import { signInWithCustomToken } from "@firebase/auth";
import { auth } from "../../firebase";

const locale = require("react-redux-i18n").I18n;

const title = {
  fontSize: 18,
  fontWeight: "bold",
  color: "black",
  fontFamily: "sans-serif",
};

const width = {
  width: 150,
};

const BoxBorder = styled.div`
  display: inline-block;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid ${(props) => (props.active ? "#1CB894" : "#f2f2f2")};
  width: 100%;
  cursor: pointer;
`;

const RadioOutside = styled.div`
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.active ? "#1CB894" : "#f2f2f2")};
  position: relative;
`;

const RadioInside = styled.div`
  position: absolute;
  left: 50%;
  top:50%
  transform:translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: ${(props) => (props.active ? "#1CB894" : "#fff")};
  border-radius: 50%;
`;

const PageSetting = () => {
  const [state, setState] = useState({
    phone: false,
    email: false,
  });

  const [isModalVisible, setIsModalVisible] = useState({
    phone: false,
    bank: false,
  });
  const [isModalPassword, setIsModalPassword] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [loading, setLoading] = useState({ switch: false });
  const [prefixPhone, setPrefixPhone] = useState(undefined);
  const [isBankStatus, setIsBankStatus] = useState();
  const [twofa, setTwofa] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const profile = user.profile;
  useEffect(() => {
    const initial = user.otp_to;

    if (user !== "") {
      setState({ phone: initial === "phone", email: initial === "email" });
    }
  }, [user.otp_to]);

  useEffect(() => {
    const country = profile.country_id;
    if (country > 0) {
      setPrefixPhone(user?.profileCountry?.prefix_phone);
    }
  }, [profile]);

  useEffect(() => {
    const timerCountDown = () => {
      setTimeout(() => {
        if (countDown > 0) {
          setCountDown(countDown - 1);
        }
      }, 1000);
    };
    timerCountDown();
  }, [countDown]);
  useEffect(() => {
    if (user) {
      setIsBankStatus(user.bookBankVerify.status);
      setTwofa(user?.enabled_2fa === 1);
    }
  }, [user]);
  const censorWord = (str) => {
    return str[0] + "*".repeat(str?.length - 5) + str?.slice(-3);
  };

  const censorEmail = (email) => {
    const arr = email.split("@");
    return `${censorWord(arr[0])}@${censorWord(arr[1])}`;
  };

  const handleCancel = () => {
    setIsModalVisible({
      phone: false,
      bank: false,
    });
  };
  const handleCancelPassword = () => {
    setIsModalPassword(false);
  };
  const onSwitch = async (el) => {
    const sw = el;
    try {
      setLoading({ switch: true });
      await post(renderApiDomain() + "switch-otp", {
        to: sw,
      });
      message.success(locale.t("switchOtp.success"));
      setLoading({ switch: false });
      dispatch(getUser());
    } catch (e) {
      message.error(locale.t("switchOtp.error"));
      setState({ phone: false, email: true });
      setLoading({ switch: false });
      dispatch(getUser());
      console.log(e);
    }
  };

  const onChangeSwitch = async (el) => {
    if (el === "phone") {
      if (state.phone) {
        setState({ phone: true, email: false });
      } else {
        await onSwitch(el);
        setState({ phone: true, email: false });
      }
    } else if (el === "email") {
      if (state.email) {
        setState({ phone: false, email: true });
      } else {
        await onSwitch(el);
        setState({ phone: false, email: true });
      }
    }
  };
  const onChangetwofa = async () => {
    try {
      const { data } = await putUserOtpEnable();

      setTwofa(data?.data?.enabled2FA);
      await dispatch(update2FA(data?.data?.enabled2FA));
      if (data?.data?.enabled2FA) {
        fetchCustomToken();
      }
      // if (data?.data?.enabled2FA) {
      //   localStorage.setItem("2fa", "1");
      // } else {
      //   localStorage.removeItem("2fa", "");
      // }
    } catch (error) {
      console?.log(error);
      message?.error("something went wrong");
    }
  };
  const fetchCustomToken = async () => {
    try {
      const { data } = await getUserCustomToken();

      signInWithCustomToken(auth, data.data.token)
        .then((userCredential) => {})
        .catch((error) => {
          const errorCode = error.code;
          switch (errorCode) {
            case "auth/user-not-found":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("User  not found");
              // Handle the error for when the user is not found
              break;
            case "auth/wrong-password":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("password is incorrect");
              // Handle the error for when the password is incorrect
              break;
            case "auth/invalid-email":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("email is invalid");
              // Handle the error for when the email is invalid
              break;
            // Handle other error codes as needed
            default:
              message.error("Something went wrong");
              // setError("Something went wrong");
              // Handle any other errors
              break;
          }
        });
    } catch (error) {
      message.error("something went wrong");
      console.log(error);
    }
  };
  const verifyFrom = false;
  const numberPhone = undefined;

  return (
    <Spin spinning={loading.switch} delay={500}>
      <div className="mt-4 bg-white p-4" style={{ borderRadius: 25 }}>
        <SubStyledAccountWallet>
          <p className="text-header-account-wallet">
            {locale.t("setting_pages")}
          </p>
          {profile !== "" ? (
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 pt-3 pb-3 mt-4">
                <div style={title}> {locale.t("main_profile.title")}</div>
                <div className="box-setting-content mt-2">
                  <div className="py-3 d-flex justify-content-between box-detail-setting">
                    <div style={width} className="text-gray">
                      {locale.t("main_profile.fullName")}
                    </div>
                    <div className="text-lightgray text-right">{`${profile?.first_name} ${profile?.last_name}`}</div>
                  </div>{" "}
                  <div className="py-3 d-flex justify-content-between box-detail-setting">
                    <div style={width} className="text-gray">
                      {locale.t("main_profile.emailAddress")}
                    </div>
                    <div className="text-lightgray">{profile?.email}</div>
                  </div>
                  <div className="py-3 d-flex justify-content-between ">
                    <div style={width} className="text-gray">
                      {locale.t("main_profile.Address")}
                      {/* {locale.t("register_choose_region")} */}
                    </div>
                    <div className="text-lightgray">
                      {user?.profileCountry?.name}
                    </div>
                  </div>
                </div>
                <div className="fs-10 mt-3">{locale.t("note_setting")}</div>
                <div className="py-3 px-3 d-flex justify-content-between box-otp-setting mt-2">
                  <div style={width} className="text-gray">
                    {locale.t("main_profile.phoneNumber")}
                  </div>
                  <div className="d-flex d-md-flex">
                    <div>
                      {profile.phone_number !== null
                        ? `${
                            user?.profileCountry?.prefix_phone
                          }*****${profile?.phone_number?.substr(7, 12)}`
                        : "-"}
                    </div>
                    <div>
                      <div
                        className="text-primary-new-iux cursor-pointer"
                        onClick={() =>
                          setIsModalVisible({ bank: false, phone: true })
                        }
                      >
                        &nbsp;{locale.t("edit")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 px-3 d-flex justify-content-between box-otp-setting mt-2">
                  <div style={width} className="text-gray">
                    {locale.t("main_profile.password")}
                  </div>
                  <div className="d-flex d-md-flex">
                    <div>************</div>
                    <div>
                      <div
                        className="text-primary-new-iux cursor-pointer"
                        onClick={() => setIsModalPassword(true)}
                      >
                        &nbsp;{locale.t("edit")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 px-3 d-flex justify-content-between box-otp-setting mt-2">
                  <div style={width} className="text-gray w-100">
                    {locale.t("twofa.twofa_title")}
                    <p className="fs-12">{locale.t("twofa.twofa_des")}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <Switch checked={twofa} onChange={onChangetwofa} />
                    </div>
                  </div>
                </div>
                {/* <div className="pt-2 d-flex justify-content-between">
                  <div style={width} className="text-gray">
                    {locale.t("main_profile.password")}
                  </div>
                  <div className="d-flex">
                    ****************** &nbsp;{" "}
                    <div
                      className="text-primary-new-iux cursor-pointer"
                      onClick={() =>
                        setIsModalVisible({ bank: false, phone: true })
                      }
                    >
                      &nbsp;Edit
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-12 col-md-12 col-lg-6 pt-3  mt-4">
                <div style={title} className="">
                  {locale.t("otp_verify")}
                </div>
                <div className="d-flex flex-column mt-2">
                  <div className="">
                    <div
                      className="box-otp-setting p-3 cursor-pointer"
                      active={state.email}
                      key="email"
                      onClick={() => {
                        onChangeSwitch("email");
                      }}
                    >
                      <div
                        className={`align-items-center d-flex justify-content-between ${
                          state.email ? "text-primary-new-iux" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <RadioOutside active={state.email}>
                              <RadioInside active={state.email} />
                            </RadioOutside>
                          </div>
                          &nbsp;
                          <div className="fs-14">
                            {locale.t("main_profile.emailAddress")}
                          </div>
                        </div>
                        <div className="">{profile.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="box-otp-setting p-3 cursor-pointer"
                      active={state.phone}
                      key="phone"
                      onClick={() => {
                        if (profile.phone_number) {
                          onChangeSwitch("phone");
                        } else {
                          setIsModalVisible({ bank: false, phone: true });
                        }
                      }}
                    >
                      <div
                        className={`align-items-center d-flex justify-content-between ${
                          state.phone ? "text-primary-new-iux" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          <RadioOutside active={state.phone}>
                            <RadioInside active={state.phone} />
                          </RadioOutside>{" "}
                          &nbsp;
                          <div className="fs-14">
                            {locale.t("main_profile.phoneNumber")}
                          </div>
                        </div>

                        <div>
                          &nbsp;&nbsp;
                          {` ${
                            profile?.phone_number !== null
                              ? `${
                                  user?.profileCountry?.prefix_phone
                                }*****${profile.phone_number?.substr(7, 12)}`
                              : "-"
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={title} className="pt-4">
                  {locale.t("payment_method")}
                </div>
                {/* <div>{locale.t("bank.description")}</div> */}
                <div className="mt-4">
                  <div className="box-otp-setting p-3">
                    <div className="d-flex justify-content-between w-100 align-items-center ">
                      {user.bookBankVerify.status === "pending" ? (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_6028_10671)">
                              <path
                                d="M7.21404 11.4285C7.11509 11.4531 7.0142 11.475 6.91407 11.4935C6.6523 11.5422 6.47923 11.7939 6.52765 12.0559C6.55156 12.1848 6.62477 12.2922 6.72416 12.3623C6.82659 12.4344 6.95696 12.4669 7.08981 12.4422C7.20906 12.4201 7.32924 12.394 7.44718 12.3646C7.70567 12.3003 7.86316 12.0384 7.79867 11.78C7.73433 11.5214 7.47269 11.3641 7.21404 11.4285Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M10.7806 4.96389C10.8144 5.06573 10.8789 5.14887 10.9604 5.20632C11.0812 5.29142 11.2393 5.32005 11.3902 5.27016C11.6432 5.18617 11.7802 4.91341 11.6965 4.66059C11.6584 4.54534 11.6161 4.42972 11.571 4.31715C11.4721 4.06978 11.1915 3.94934 10.944 4.04829C10.6967 4.14719 10.5763 4.42787 10.6753 4.67532C10.7131 4.76994 10.7486 4.86706 10.7806 4.96389Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M8.78018 10.7402C8.69508 10.7963 8.60725 10.8507 8.51877 10.9017C8.28793 11.0348 8.20887 11.3299 8.34197 11.5606C8.37811 11.6234 8.42625 11.6747 8.48168 11.7139C8.63049 11.8186 8.8326 11.8343 9.00086 11.7374C9.10606 11.6767 9.21066 11.6122 9.31201 11.5451C9.53421 11.3983 9.59537 11.099 9.44849 10.8767C9.30164 10.6543 9.00246 10.5933 8.78018 10.7402Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M11.9957 6.31113C11.9852 6.04488 11.7609 5.83764 11.4946 5.84803C11.2286 5.85853 11.0212 6.08287 11.0316 6.34903C11.0356 6.45079 11.0367 6.55408 11.0343 6.65574C11.0306 6.82271 11.1122 6.97155 11.2392 7.06101C11.3148 7.11425 11.4065 7.14647 11.506 7.14874C11.7723 7.15464 11.9929 6.94346 11.9988 6.67707C12.0015 6.5556 12.0005 6.43253 11.9957 6.31113Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M10.701 9.47167C10.4875 9.31148 10.1854 9.35497 10.0255 9.56809C9.9643 9.64971 9.89981 9.73032 9.83377 9.80794C9.6612 10.0107 9.68558 10.3152 9.88833 10.4879C9.89988 10.4977 9.91157 10.5067 9.92369 10.5152C10.1253 10.6573 10.4054 10.6246 10.5684 10.4334C10.6472 10.3408 10.7241 10.2445 10.7973 10.147C10.9572 9.9339 10.9139 9.63163 10.701 9.47167Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M11.4089 7.7273C11.1547 7.64759 10.884 7.7891 10.8043 8.04332C10.7739 8.14044 10.7401 8.23802 10.7036 8.33359C10.6235 8.54398 10.7002 8.77547 10.8762 8.89952C10.9084 8.92219 10.944 8.94141 10.9826 8.95601C11.2315 9.05099 11.5102 8.92611 11.6051 8.67711C11.6484 8.56348 11.6886 8.44738 11.7249 8.33192C11.8045 8.07765 11.6631 7.80701 11.4089 7.7273Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M5.10744 11.4986C4.67615 11.4212 4.26241 11.2893 3.87009 11.1054C3.86545 11.103 3.8613 11.1002 3.85642 11.098C3.76397 11.0545 3.67168 11.0079 3.58222 10.9592C3.58191 10.9588 3.58134 10.9586 3.58085 10.9584C3.41671 10.868 3.25653 10.768 3.1009 10.6584C0.831632 9.05988 0.286009 5.91316 1.88465 3.64391C2.23227 3.15066 2.65294 2.73914 3.12192 2.41299C3.1277 2.40896 3.13348 2.40496 3.13921 2.40091C4.79184 1.2622 7.03586 1.18546 8.79069 2.35559L8.41379 2.90016C8.30901 3.05173 8.37347 3.16219 8.5569 3.14568L10.1941 2.99911C10.3777 2.9826 10.4876 2.82373 10.4382 2.64636L9.99853 1.06237C9.94931 0.884792 9.8233 0.863536 9.71842 1.01508L9.34064 1.56094C8.05284 0.69646 6.50824 0.366821 4.97514 0.632668C4.82073 0.659392 4.66849 0.6921 4.51833 0.730174C4.51717 0.730381 4.51624 0.73051 4.51531 0.730716C4.50951 0.732135 4.50363 0.734018 4.49798 0.735591C3.17597 1.07508 2.02253 1.84613 1.19947 2.94625C1.19254 2.95448 1.18539 2.96253 1.17884 2.97148C1.15147 3.00834 1.12431 3.04606 1.09771 3.08377C1.05422 3.14557 1.01135 3.20893 0.970334 3.27228C0.965201 3.27992 0.96128 3.28768 0.956792 3.29539C0.2776 4.34787 -0.0500012 5.56464 0.0061811 6.80338C0.00631008 6.80746 0.00607792 6.81156 0.0061811 6.81574C0.0116239 6.93674 0.0212198 7.05943 0.0342465 7.1802C0.0349429 7.18799 0.0366712 7.19537 0.0379868 7.20316C0.051452 7.3246 0.0683479 7.44633 0.0895259 7.56803C0.304762 8.80977 0.890523 9.92715 1.76826 10.7966C1.7703 10.7986 1.77241 10.8008 1.77448 10.803C1.7752 10.8038 1.776 10.8042 1.7767 10.8049C2.01252 11.0375 2.26903 11.2526 2.54522 11.4472C3.268 11.9565 4.07284 12.2931 4.9372 12.4482C5.19946 12.4954 5.44998 12.3208 5.49703 12.0586C5.54406 11.7963 5.36963 11.5456 5.10744 11.4986Z"
                                fill="#009EFF"
                              />
                              <path
                                d="M5.70296 2.64648C5.48724 2.64648 5.3125 2.82138 5.3125 3.03679V6.92537L8.86891 8.76383C8.92615 8.79347 8.98744 8.80742 9.04774 8.80742C9.189 8.80742 9.32543 8.7305 9.39479 8.59629C9.49372 8.40473 9.41886 8.1694 9.22731 8.07047L6.09299 6.45006V3.03679C6.09296 2.82138 5.91843 2.64648 5.70296 2.64648Z"
                                fill="#009EFF"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6028_10671">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          &nbsp; In Progress: Bank Account
                        </div>
                      ) : user?.bookBankVerify?.bank_account_number === null ||
                        user?.bookBankVerify?.status === "rejected" ? (
                        <div>-</div>
                      ) : (
                        <div>
                          *****
                          {user?.bookBankVerify?.bank_account_number.substr(
                            6,
                            user?.bookBankVerify?.bank_account_number?.length
                          )}
                          <div className="text-lightgray">
                            {user?.bookBankVerify?.bank_name}
                          </div>
                        </div>
                      )}

                      {user?.bookBankVerify?.status !== "pending" && (
                        <div
                          className="ml-3 text-primary-new-iux cursor-pointer"
                          href="#"
                          onClick={() =>
                            setIsModalVisible({ bank: true, phone: false })
                          }
                        >
                          {locale.t("edit")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div
                className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
                style={{ height: 500 }}
              >
                <ClipLoader
                  color="#36d7b7"
                  size={72}
                  cssOverride={{ borderWidth: 8 }}
                />
                <p className="mt-5 text-lightgray-iux">
                  {locale.t("loadingtitle")}
                </p>
              </div>
              {/* <VerifyBookBank /> */}
            </>
          )}
        </SubStyledAccountWallet>
      </div>
      <Dialog
        verifyFrom={verifyFrom}
        numberPhone={numberPhone}
        profile={profile}
        prefixPhone={user?.profileCountry?.prefix_phone}
        locale={locale}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <ChangePassword
        isModalVisible={isModalPassword}
        handleCancel={handleCancelPassword}
      />
    </Spin>
  );
};

export default PageSetting;
