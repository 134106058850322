import Card from "react-credit-cards";
import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./units";
// import Supportcard from "./Card";
import { connect } from "react-redux";

function Creditcard(props) {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [issuer, setIssuer] = useState("");
  const [focused, setFocused] = useState("");

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      setNumber(target.value);
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "name") {
      setName(target.value);
      target.value = target.value;
    } else if (target.name === "expiry") {
      setExpiry(target.value);
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      setCvc(target.value);
      target.value = formatCVC(target.value);
    }
  };

  const onFinish = (values) => {};

  return (
    <div key="Payment">
      <div className="App-payment">
        <Card
          number={number}
          name={name}
          expiry={expiry}
          cvc={cvc}
          focused={focused}
          callback={handleCallback}
        />
        <form className="form-creditcard">
          <div className="form-group">
            <input
              type="search"
              name="number"
              className="form-control"
              placeholder="Card Number"
              pattern="[\d| ]{16,22}"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
            <small>E.g.: 49..., 51..., 36..., 37...</small>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Name"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <input
                type="search"
                name="expiry"
                className="form-control"
                placeholder="Valid Thru"
                pattern="\d\d/\d\d"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
            <div className="col-6">
              <input
                type="search"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                pattern="\d{3,4}"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
          </div>
          <input type="hidden" name="issuer" value={issuer} />
          <div className="form-actions">
            <button className="btn btn-primary btn-block" onClick={onFinish}>
              PAY
            </button>
          </div>
        </form>
        {/* <Supportcard /> */}
      </div>
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => ({
  locale: i18n.locale,
  user: user,
});

export default connect(mapStateToProps, null)(Creditcard);
