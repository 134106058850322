import { Col, Row, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postFormPayment = ({
  amount_thb,
  account_trade_id,
  ipaddress,
  payment_key,
  deposit_coupon_id,
}) => {
  return post(`${renderApiDomain() + `deposit/${payment_key}`}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    deposit_coupon_id: deposit_coupon_id,
  });
};

const FormPayment = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
  payment_key,
  paymentId,
  amount,
  account,
  onBackStep,
}) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  //   const { paymentId } = props;
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    host: "",
    api_token: "",
    website_id: "",
    action: "",
    price: "",
    curr: "",
    product_name: "",
    fullname: "",
    email: "",
    bill_street_1: "",
    bill_city: "",
    bill_country: "",
    bill_zip: "",
    bill_phone: "",
    id_order: "",
    notify_url: "",
    success_url: "",
    error_url: "",
    cardsend: "",
    source: "",
  });

  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: account.id,
        amount_thb: +amount,
        payment_key: payment_key,
        deposit_coupon_id: isCoupon,
      };
      const { data } = await postFormPayment(payload);

      if (data) {
        setLoading(true);

        await Promise.all([setPaymentData(data.data)]).then(() => {
          refPerfect.current.click();
        });
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }

      onBackStep("error");
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <form id="paymentForm" method="post" action={paymentData.host}>
        ​
        {Object.keys(paymentData).map((e, i) => (
          <input type="hidden" name={e} value={Object.values(paymentData)[i]} />
        ))}
        <input
          ref={refPerfect}
          type="submit"
          name="Submit"
          value="Submit"
          hidden
        />
      </form>
    </Spin>
  );
};

export default FormPayment;
