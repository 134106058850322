import { Col, Spin, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { get, post } from "../../../../helper/request";
import { LoadingOutlined } from "@ant-design/icons";
import BoxSelectPayment from "../../../../components/boxpayment/BoxSelectPayment";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";
import { useDispatch } from "react-redux";

export const b2binpayApi = (page) => {
  return get(`${renderApiDomain() + `deposit/b2binpay?page=${page}`}`);
};
export const b2binpayPostApi = (data) => {
  return post(`${renderApiDomain() + `deposit/b2binpay`}`, data);
};

const B2binpay = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const dispatch = useDispatch();
  const {
    paymentId,
    account: { id },
  } = props;
  const [page, setPage] = useState(3);
  const [loading, setLoading] = useState(false);
  const [isData, setData] = useState({
    links: {
      first: "",
      last: "",
      next: "",
    },
    wallets: [],
    maxPage: 0,
    nextPage: null,
  });

  const [payload, setPayload] = useState({
    wallet_id: 0,
    account_trade_id: id,
    payment_id: paymentId,
  });

  const onNextPage = async (el) => {
    try {
      setLoading(true);
      const { data } = await b2binpayApi(el);
      if (data) {
        // const a = data.data.links.last.split("page=");
        setData(() => ({
          ...data?.data,
          wallets: [...isData?.wallets, ...data?.data],
          maxPage: data.total / data.limit,
          nextPage: data.next,
        }));
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    onNextPage(1);
  }, []);

  const onFinish = async (el, currency_id) => {
    try {
      setLoading(true);
      payload.wallet_id = el;
      payload.currency_id = currency_id;
      payload.deposit_coupon_id = isCoupon;
      const { data } = await b2binpayPostApi(payload);
      if (data) {
        window.location.assign(data?.data?.link);
      }
      setLoading(false);
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        {isData.wallets?.map((e) => (
          <Col xs={24} sm={12} lg={8}>
            <BoxSelectPayment
              onSubmit={() => onFinish(e.id, e.currency_id)}
              title={e.name.replace(", IUX Markets", "")}
              images={
                process.env.REACT_APP_HOST +
                `/assets/img/logo-crypto/${e.currency_id}.png`
              }
            />
          </Col>
        ))}
      </Row>
      <div className="text-center">
        {page !== 1 && isData?.nextPage && (
          <button
            className="btn text-primary-sub-iux"
            onClick={() => {
              onNextPage(page - 1);
              setPage(page - 1);
            }}
          >
            Show more
          </button>
        )}
      </div>
    </Spin>
  );
};

export default B2binpay;
