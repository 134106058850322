import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import mybonus from "../../mock/mybonus.json";
import { useSelector } from "react-redux";
import { Alert, Button, Divider } from "antd";
import { getUserCredit } from "../../api/account";
import bonusCredit from "../../helper/bonusCredit";
import CountdownTimer from "../campaign/CoutdownTimer";

import ModalCloseCampaign from "../campaign/ModalCloseCampaign";
import { useDispatch } from "react-redux";
import {
  getBonusCampaign,
  updateAlertOpenAccount,
  updateModalCampaign,
} from "../../actions";
const locale = require("react-redux-i18n").I18n;
function FooterBonus() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const accounts = useSelector((state) => state.user.accounts);
  const dataCampaign = useSelector((state) => state.user.bonus_campaign);
  const [dataCreditOther, setDataCreditOther] = useState(null);
  const [isModalCloseCampaign, setIsModalCloseCampaign] = useState(false);
  // const [dataCampaign, setDataCampaign] = useState(null);
  const [bonus, setBonus] = useState([
    user.bonus_balance_30,
    user.bonus_balance_15,
  ]);
  const [isCloseCampaign, setisCloseCampaign] = useState(false);
  const fetchCreditOther = async () => {
    try {
      const { data } = await getUserCredit();
      setDataCreditOther(data.data);
    } catch (error) {}
  };

  const onCloseCampaign = () => {
    dispatch(getBonusCampaign());
    setIsModalCloseCampaign(false);
  };
  useEffect(() => {
    fetchCreditOther();
    // fetchCreditCampaign();
  }, []);
  useEffect(() => {
    if (
      user.bonus_balance_30 !== bonus[0] ||
      user.bonus_balance_15 !== bonus[1]
    ) {
      setBonus([user.bonus_balance_30, user.bonus_balance_15]);
    }
  }, [user]);
  const isDisableButton = (value) => {
    if (value === "35%") {
      if (dataCreditOther?.find((e) => e.credit_remain > 0)) {
        return true;
      } else if (user.bonus_balance_30 <= 0) {
        return true;
      } else {
        return false;
      }
    } else if (value === "25%") {
      if (dataCreditOther?.find((e) => e.credit_remain > 0)) {
        return true;
      } else if (user.bonus_balance_15 <= 0) {
        return true;
      } else if (user.bonus_balance_30 > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [bonusData, setBonusData] = useState([]);

  useEffect(() => {
    const fetchBonus = async () => {
      const bonusData = await bonusCredit();
      setBonusData(bonusData);
    };
    fetchBonus();
  }, []);
  useEffect(() => {
    if (
      new Date(dataCampaign?.start_date) <= new Date() &&
      new Date(dataCampaign?.end_date) > new Date()
    ) {
      console.log(dataCampaign, "dataCampaign");
      setisCloseCampaign(false);
    } else {
      setisCloseCampaign(true);
    }
  }, [dataCampaign]);
  const handleClose = () => {
    setisCloseCampaign(true);
    dispatch(updateModalCampaign(true));
    // Do something when the countdown reaches zero
  };
  return (
    <div>
      {" "}
      <div
        style={{
          padding: 24,
          background: "rgb(255, 255, 255)",
          marginTop: 16,
          borderRadius: 25,
        }}
      >
        <div>
          <div className="d-flex">
            <div>
              <h2 className="ml-3 fs-20 fw-700">
                {locale.t("dashboardWallet.myBonus")}
              </h2>
            </div>
          </div>{" "}
          <div className="row">
            {dataCampaign &&
              new Date(dataCampaign?.start_date) <= new Date() &&
              new Date(dataCampaign?.end_date) > new Date() &&
              !isCloseCampaign && (
                <div className="col-12 col-md-6  col-lg-4 mt-2 " key={+"other"}>
                  <div
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #F2F2F2",
                      // width: 280,
                      // marginRight: 20,
                    }}
                    className="h-100"
                  >
                    <div
                      style={{
                        fontWeight: "700",
                        borderRadius: "20px 20px 0 0",
                        fontSize: "24px",
                        color: "#FFF",
                        background: `url('/assets/images/campaigns/bg-campaign-card.png')`,
                        backgroundSize: "cover",
                      }}
                      className="text-center pt-3 pb-3"
                    >
                      {/* {dataCampaign?.name} */}
                      {locale.t("bonus_campaign_card_title")}
                    </div>

                    <div style={{ padding: 20 }}>
                      <div className="d-flex justify-content-between">
                        <div> {locale.t("bonus_campaign_card_maximum")}</div>
                        <div className="ml-2">
                          ${dataCampaign?.maximum_bonus.toFixed(2)}
                        </div>
                      </div>{" "}
                      <Divider style={{ margin: "12px 0" }} />
                      <CountdownTimer
                        targetDate={new Date(dataCampaign?.end_date)}
                        onComplete={handleClose}
                      />
                      <>
                        {/* <div className="fs-12 fw-700">
                      {locale.t("dashboardWallet.tooltip.title")}
                    </div> */}
                        <div className="fs-12 mt-2">
                          {/* {dataCampaign?.description} */}
                          {locale.t("bonus_campaign_card_detail")}
                        </div>
                        {accounts?.filter(
                          (account) =>
                            account.type === "real" &&
                            account.account_type === "StandardBonus"
                        ).length > 0 ? (
                          <Link
                            to={`/${localStorage.getItem(
                              "locale"
                            )}/wallet/fund/deposit?coupon=${dataCampaign?.id}`}
                          >
                            <Button
                              className="btn-primary-new-iux mt-4 w-100"
                              // disabled={e.credit_remain <= 0}
                            >
                              {locale.t("bonus_campaign_card_button")}
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            className="btn-primary-new-iux mt-4 w-100"
                            onClick={() =>
                              dispatch(updateAlertOpenAccount(true))
                            }
                          >
                            {locale.t("bonus_campaign_card_button")}
                          </Button>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              )}
            {mybonus.bonus.map((data, index) => {
              return (
                <div
                  className="col-12 col-md-6  col-lg-4 mt-2"
                  key={index + "bonus"}
                >
                  <div
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #F2F2F2",
                      // width: 280,
                      // marginRight: 20,
                    }}
                    className="h-100"
                  >
                    <div
                      style={{
                        fontWeight: "700",
                        borderRadius: "20px 20px 0 0",
                        fontSize: "24px",

                        background: `linear-gradient(55deg, rgba(220,190,89,1) 0%, rgba(255,233,157,1) 100%)`,
                      }}
                      className="text-center pt-3 pb-3"
                    >
                      {bonusData[index]?.bonus_pct}% Bonus
                    </div>
                    <div
                      style={{ padding: 20, height: "calc(100% - 68px)" }}
                      className="d-flex flex-column justify-content-between "
                    >
                      <div>
                        <div className="d-flex justify-content-between">
                          <div>{locale.t("dashboardWallet.remainBonus")}</div>
                          <div className="ml-2">
                            $
                            {bonus
                              ? parseFloat(bonus[index] || 0).toFixed(2)
                              : 0}
                          </div>
                        </div>
                        <Divider style={{ margin: "12px 0" }} />
                        <div className="fs-12 fw-700">
                          {locale.t("dashboardWallet.tooltip.title")}
                        </div>
                        <div className="fs-12 mt-2">
                          {locale.t("dashboardWallet.tooltip.description")}
                        </div>
                      </div>
                      {accounts ? (
                        accounts.filter(
                          (account) =>
                            account.type === "real" &&
                            account.account_type === "StandardBonus"
                        ).length > 0 ? (
                          <Link
                            to={`/${localStorage.getItem(
                              "locale"
                            )}/wallet/fund`}
                          >
                            <Button
                              disabled={isDisableButton(data.title)}
                              className="btn-primary-new-iux mt-4 w-100"
                            >
                              {locale.t("dashboardWallet.deposit")}
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            to={`/${localStorage.getItem(
                              "locale"
                            )}/wallet/fund`}
                          >
                            <Button
                              className="btn-primary-new-iux mt-4 w-100"
                              disabled
                            >
                              {locale.t("dashboardWallet.deposit")}
                            </Button>
                          </Link>
                        )
                      ) : (
                        <Alert
                          message={locale.t("dashboardWallet.notAccount")}
                          type="error"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {dataCreditOther
              ?.filter((e) => e.credit_remain > 0)
              ?.map((e, i) => (
                <div
                  className="col-12 col-md-6  col-lg-4 mt-2"
                  key={i + "other"}
                >
                  <div
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #F2F2F2",
                      // width: 280,
                      // marginRight: 20,
                    }}
                    className="h-100"
                  >
                    <div
                      style={{
                        fontWeight: "700",
                        borderRadius: "20px 20px 0 0",
                        fontSize: "24px",
                        color: "#FFF",
                        background: `linear-gradient(55deg, #169376 0%, #1CB894 100%)`,
                      }}
                      className="text-center pt-3 pb-3"
                    >
                      {e?.rate}% Bonus
                    </div>
                    <div
                      style={{ padding: 20 }}
                      className="d-flex flex-column justify-content-between"
                    >
                      <>
                        <div className="d-flex justify-content-between">
                          <div>{locale.t("dashboardWallet.remainBonus")}</div>
                          <div className="ml-2">
                            ${e?.credit_remain?.toFixed(2)}
                          </div>
                        </div>
                        <Divider style={{ margin: "12px 0" }} />
                        <div className="fs-12 fw-700">
                          {locale.t("dashboardWallet.tooltip.title")}
                        </div>
                        <div className="fs-12 mt-2">
                          {locale.t("dashboardWallet.tooltip.description")}
                        </div>
                      </>
                      {accounts?.filter(
                        (account) =>
                          account.type === "real" &&
                          account.account_type === "StandardBonus"
                      ).length > 0 ? (
                        <Link
                          to={`/${localStorage.getItem("locale")}/wallet/fund`}
                        >
                          <Button
                            className="btn-primary-new-iux mt-4 w-100"
                            disabled={e.credit_remain <= 0}
                          >
                            {locale.t("dashboardWallet.deposit")}
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          to={`/${localStorage.getItem("locale")}/wallet/fund`}
                        >
                          <Button
                            className="btn-primary-new-iux mt-4 w-100"
                            disabled
                          >
                            {locale.t("dashboardWallet.deposit")}{" "}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBonus;
