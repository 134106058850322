import { Button, Modal, Steps } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;
function ModalCloseCampaign(props) {
  const { isOpen, onClose } = props;

  const history = useHistory();
  return (
    <Modal
      //   style={{
      //     background: "url('/assets/images/campaigns/bg-modal-campaign.png')",
      //     backgroundSize: "cover",
      //     backgroundPosition: "center",
      //   }}
      className="width-modal-swap user user-change "
      closable={false}
      visible={isOpen}
      footer={
        <>
          <div
            className="d-flex "
            style={{ gap: 20, marginTop: 31, marginBottom: 20 }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              className="btn-primary-new-iux w-100 "
              type="primary"
              style={{ borderRadius: 8 }}
            >
              {locale.t("depositPage.dialog.okButton")}
            </Button>
          </div>
        </>
      }
      onCancel={() => {
        onClose();
      }}
    >
      <div
        className="d-flex justify-content-end cursor-pointer"
        onClick={() => onClose()}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.781396 16.0001C0.626858 16.0001 0.475783 15.9543 0.347281 15.8685C0.218778 15.7826 0.118621 15.6606 0.0594776 15.5178C0.000334661 15.3751 -0.0151369 15.218 0.0150198 15.0664C0.0451766 14.9148 0.119607 14.7756 0.228896 14.6664L14.6664 0.228853C14.8129 0.0823209 15.0117 0 15.2189 0C15.4261 0 15.6249 0.0823209 15.7714 0.228853C15.9179 0.375385 16.0002 0.574125 16.0002 0.781353C16.0002 0.988581 15.9179 1.18732 15.7714 1.33385L1.3339 15.7714C1.26141 15.844 1.17528 15.9016 1.08047 15.9408C0.985653 15.9801 0.884016 16.0002 0.781396 16.0001Z"
            fill="white"
          />
          <path
            d="M15.2189 16.0001C15.1162 16.0002 15.0146 15.9801 14.9198 15.9408C14.825 15.9016 14.7388 15.844 14.6664 15.7714L0.228853 1.33385C0.0823209 1.18732 0 0.988581 0 0.781353C0 0.574125 0.0823209 0.375385 0.228853 0.228853C0.375385 0.0823209 0.574125 0 0.781353 0C0.988581 0 1.18732 0.0823209 1.33385 0.228853L15.7714 14.6664C15.8806 14.7756 15.9551 14.9148 15.9852 15.0664C16.0154 15.218 15.9999 15.3751 15.9408 15.5178C15.8816 15.6606 15.7815 15.7826 15.653 15.8685C15.5245 15.9543 15.3734 16.0001 15.2189 16.0001Z"
            fill="white"
          />
        </svg>
      </div>
      <h4 className="fs-20 fw-700  text-center">
        {locale.t("bonus_campaign_expired_title")}
      </h4>

      <p className=" fs-14 text-center">
        {locale.t("bonus_campaign_expired_des")}
      </p>
    </Modal>
  );
}

export default ModalCloseCampaign;
