import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Result,
  Select,
  Tooltip,
  Collapse,
} from "antd";
import { BarLoader } from "react-spinners";
import { post } from "../../../helper/request";
import { connect, useDispatch, useSelector } from "react-redux";
import DepositFirstStep from "./deposit/DepositFirstStep";
import DepositSecondStep from "./deposit/DepositSecondStep";
import DepositThirdStep from "./deposit/DepositThirdStep";
import { Link, useHistory } from "react-router-dom";
import OpenAccount from "../../../components/open-account/OpenAccount";
import styled from "styled-components";
import { CheckCerrency } from "../../../components/checkcurrency";
import BoxFAQ from "../../../components/boxfaq/BoxFAQ";
import BoxSelectPayment from "../../../components/boxpayment/BoxSelectPayment";
import { Base64Encoder } from "../../../helper/Base64Encoder";
import { renderApiDomain } from "../../../helper/renderApiDomain";
import ModalCloseCampaign from "../../../components/campaign/ModalCloseCampaign";
import { getBonusCampaign } from "../../../actions";
import { useLocation } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
const { Panel } = Collapse;

const customPanelStyle = {
  background: "#FFF",
  borderRadius: 4,
  fontSize: 12,
  // marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

export const Deposit = (props) => {
  const { user, handleSetStep } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const [isModalCloseCampaign, setIsModalCloseCampaign] = useState(false);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const payment = useSelector(({ user }) => user.bank);
  const [message, setMessage] = useState({ title: "", description: "" });
  // const [amount, setAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [account, setAccount] = useState(props.account);
  const [paymentId, setPaymentId] = useState(2);
  const [paymentKey, setPaymentKey] = useState("");
  const [payment_config, setPayment_config] = useState({
    minimum_deposit: 100,
    maximum_deposit: 1000000,
    minimum_withdraw: 500,
    maximum_withdraw: 1000000,
  });
  let { minimum_deposit, maximum_deposit } = payment_config;
  const [payment_name, setPayment_name] = useState("");

  const [payment_id, setPayment_id] = useState(1);
  const [payment_key, setPayment_key] = useState(null);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [amount, setAmount] = useState(0);
  const [accountSelected, setAccountSelected] = useState(undefined);
  const [paymentDefault, setPaymentDefault] = useState(1);
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [alphaSymbol, setAlphaSymbol] = useState([]);
  const [step, setStep] = useState(null);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState(
    props.user?.accounts?.filter((e) => e.type === "real")
  );
  const location = useLocation();

  const renderDefaultAccount = async () => {
    let urlParams = new URLSearchParams(window.location.search);

    let isCoupon = urlParams?.get("coupon");
    let account_number = urlParams?.get("account");
    // props.user.accounts.find((e) => e.id === value);

    if (
      props.user?.accounts?.filter((e) => e.type === "real")?.length > 0 &&
      (isCoupon || account_number)
    ) {
      if (isCoupon) {
        setAccountSelected(undefined);
        setAccountList(
          props.user?.accounts
            ?.filter((e) => e.type === "real")
            .map((e) => {
              if (e.account_type === "StandardBonus") {
                e.disable = false;
              } else {
                e.disable = true;
              }
              return e;
            })
        );
        // setAccountList()
      }
      if (account_number) {
        if (
          props.user?.accounts?.find(
            (e) => e.account_number === account_number && e.type === "real"
          )
        ) {
          await setAccountSelected(
            props.user?.accounts?.find(
              (e) => e.account_number === account_number
            )
          );
        } else {
          history.push(
            `/${localStorage.getItem("locale")}/wallet/fund/deposit`
          );
          setAccountSelected(null);
        }

        setStep(0);
      } else {
        setStep(0);
      }
    } else {
      setAccountList(
        props.user?.accounts
          ?.filter((e) => e.type === "real")
          .map((e) => {
            e.disable = false;
            return e;
          })
      );
      setStep(0);
    }
  };

  useEffect(() => {
    setLoading(true);
    renderDefaultAccount();
    setLoading(false);
  }, [props?.user?.accounts, location]);
  useEffect(() => {
    setLoading(true);

    if (paymentKey === "alphapo") {
      post(`${renderApiDomain()}deposit/alphapo/pairs`, {
        currency_from: "",
        type: "deposit",
      })
        .then(({ data: { data } }) => {
          setAlphaSymbol(data);
          setRate(parseFloat(data[0].rate_to));
          setSuffix(data[0]?.currency_to.currency);
          setPrefix(data[0]?.currency_from.currency);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSuffix(profileCountry?.symbol);
      setPrefix("USD");
    }
    setLoading(false);
    return () => {
      setAccountList(
        props.user?.accounts
          ?.filter((e) => e.type === "real")
          .map((e) => {
            e.disable = false;
            return e;
          })
      );
    };
  }, []);
  useEffect(() => {
    setLoading(true);

    setCountryId(props?.user?.profile?.country_id);
    if (props.user.bank) {
      const defaultPayment = payment?.find((e) => e.recommend === 1);
      if (payment?.find((e) => e.recommend === 1)) {
        setPayment_key(defaultPayment?.payment_key);
        setPaymentDefault(defaultPayment?.payment_key);
        setPaymentSelected(props.user.bank.find((e) => e.recommend === 1));
      } else {
        if (props.user.bank.length > 0) {
          setPayment_key(props.user.bank[0]?.payment_key);
          setPaymentDefault(props.user.bank[0]?.payment_key);
          setPaymentSelected(props.user.bank[0]);
        } else {
          setPayment_key(null);
          setPaymentDefault(null);
          setPaymentSelected(null);
        }
      }
    } else {
      setPayment_key(null);
      setPaymentDefault(null);
      setPaymentSelected(null);
    }

    setLoading(false);
  }, [props?.user?.profile?.country_id]);

  const onBackStep = (isError) => {
    setAmount(0);
    if (isError === "error") {
      setStep(0);
    } else {
      if (step === 1) {
        setAccountSelected(null);
        setPayment_key(null);
        setPaymentDefault(null);
        setPaymentSelected(null);
      }
      setStep(step - 1);
    }
  };
  useEffect(() => {
    handleSetStep(step);
  }, [step]);
  const onsetRate = (value) => {
    setRate(value);
  };
  const onSuccess = (message) => {
    setShowSuccess(true);
    setMessage(message.message);

    setStep(step + 1);
  };
  const onNextStep = () => {
    setStep(step + 1);
  };
  const onNextStepPayment = (state) => {
    setStep(step + 1);
  };
  const onChangeAmount = (value) => {
    setAmount(value);
  };
  const onChangePayment = (value) => {
    if (props.user.bank.find((e) => e.payment_key === value)) {
      setPaymentSelected(props.user.bank.find((e) => e.payment_key === value));
    }
    setPayment_key(value);
  };

  const onAccountSelected = (value) => {
    if (props.user.accounts.find((e) => e.id === value)) {
      setAccountSelected(props.user.accounts.find((e) => e.id === value));
    }
  };
  const dataCampaign = useSelector((state) => state.user.bonus_campaign);

  const onCloseCampaign = () => {
    dispatch(getBonusCampaign());
    setIsModalCloseCampaign(false);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <DepositFirstStep
            key={"deposit step 1"}
            locale={locale}
            accountList={accountList}
            accounts={props.accounts}
            onAccountSelected={onAccountSelected}
            onNextStep={onNextStep}
            accountSelected={accountSelected}
            onChangePayment={onChangePayment}
            paymentKey={payment_key}
            paymentDefault={paymentDefault}
            paymentSelected={paymentSelected}
          />
        );
      case 1:
        return (
          <Fragment>
            <DepositSecondStep
              key={"deposit step 2"}
              amount={amount}
              accountList={accountList}
              paymentId={paymentSelected.id}
              paymentKey={payment_key}
              payment_config={paymentSelected.payment_config[0]}
              payment_name={payment_name}
              account={accountSelected}
              onSuccess={onSuccess}
              onBackStep={onBackStep}
              onNextStepPayment={onNextStepPayment}
              onChangeAmount={onChangeAmount}
              onsetRate={onsetRate}
              paymentSelected={paymentSelected}
            />
          </Fragment>
        );
      case 2:
        return (
          <>
            <div
              style={{ borderRadius: 20 }}
              headStyle={{ textAlign: "center" }}
              title="Deposit"
              visible={step === 2}
              width={580}
              className="user"
            >
              <DepositThirdStep
                key={"deposit step 3"}
                amount={amount}
                paymentId={paymentSelected?.id}
                paymentKey={payment_key}
                payment_config={paymentSelected?.payment_config[0]}
                payment_name={payment_name}
                account={accountSelected}
                suffix={suffix}
                onSuccess={onSuccess}
                onBackStep={onBackStep}
                onNextStepPayment={onNextStepPayment}
                rate={rate}
                paymentSelected={paymentSelected}
              />
            </div>
          </>
        );
      default: {
        if (showSuccess) {
          return (
            <Result
              status="success"
              title={message.title}
              subTitle={message.description}
              extra={[
                <Link
                  to={`/${localStorage.getItem("locale")}/wallet`}
                  className="text-center"
                >
                  <Button className="btn-primary-new-iux  button-default-w">
                    {locale.t("deposit_info.done")}
                  </Button>
                </Link>,
              ]}
            />
          );
        }
      }
    }
  };
  return (
    <div className="px-2 pb-2 px-sm-4 pb-sm-2">
      {/* <a ref={refLinkPayment} href={linkPay} hidden>
        LinkPayment
      </a> */}
      {!loading ? (
        <div className="row ">
          <div className="col-12 col-xl-8 ">
            <div style={{ maxWidth: "100%" }}>
              {accountList && accountList?.length > 0 ? (
                renderStep(step)
              ) : (
                <OpenAccount />
              )}
            </div>
          </div>
          <div className="d-none d-xl-block col-0 col-lg-4 user">
            <BoxFAQ step={step} type={"deposit"} />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="text-center mb-4">
            <img
              alt="IUX"
              className="registerlogo border-logo"
              src={"/assets/images/icons/iux-logo.png"}
            />
          </div>
          <div>
            <BarLoader
              color="#00AF6C"
              height={4}
              width={200}
              css="border-radius: 4px;"
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  const { accounts, profile, account } = user;
  return { accounts, profile, account, locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(Deposit);
